import React, { useState } from 'react';
import ReactModal from 'react-modal';

import InitiateGlobalState from './initiateGlobalState';

const AppActions = () => {
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');

  ReactModal.setAppElement('body');

  function handleHelpButtonPress() {
    setModalTitle('Need help?');
    setModalBody(
      <>
        <p>
          If you need help using our LED Strip Configurator app or have an
          enquiry regarding the product, please contact our team.
        </p>
        <p className='large-emphasis' />{' '}
        <div className='button-group button-group--vertical'>
          <button
            onClick={() => {
              handleSendEmailButtonPress();
            }}
            className='button button--primary'
          >
            Send us an Email
          </button>
          <button
            onClick={() => {
              handleCancelButtonPress();
            }}
            className='button'
          >
            Cancel
          </button>
        </div>
      </>
    );

    setShowDialogBox(true);
  }

  function handleResetAppButtonPress() {
    setModalTitle('Are you sure?');
    setModalBody(
      <>
        <p>
          Resetting the app will clear all of your selections and reset the
          configurator to its initial state. This cannot be undone.
        </p>
        <p className='large-emphasis' />{' '}
        <div className='button-group button-group--vertical'>
          <button
            onClick={() => {
              handleYesResetAppButtonPress();
            }}
            className='button button--danger'
          >
            Yes, reset the app
          </button>
          <button
            onClick={() => {
              handleCancelButtonPress();
            }}
            className='button'
          >
            Cancel
          </button>
        </div>
      </>
    );

    setShowDialogBox(true);
  }

  function handleYesResetAppButtonPress() {
    setShowDialogBox(false);
    InitiateGlobalState(true, false);
    window.location.href = '/';
  }
  function handleSendEmailButtonPress() {
    setShowDialogBox(false);
    window.location.href =
      'mailto:customer.services@jcc.co.uk?subject=JCC LED Strip Configurator Support Request';
  }
  function handleCancelButtonPress() {
    setShowDialogBox(false);
  }

  return (
    <>
      {' '}
      <ReactModal
        isOpen={showDialogBox}
        style={{
          overlay: {
            backgroundColor: '#000',
            opacity: '0.8',
            zIndex: '1000000000000'
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            borderRadius: 'none',
            border: 'none',
            marginRight: '-50%',
            minWidth: '320px',
            padding: '0px',
            opacity: '1.0',
            transform: 'translate(-50%, -50%)',
            zIndex: '1000000000000000'
          }
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
      >
        <div className='ReactModal__Content__Title'>
          <h3>{modalTitle}</h3>
        </div>
        <div className='ReactModal__Content__Body'>{modalBody}</div>
      </ReactModal>
      <div className='app-actions'>
        <button
          className='help-link text-button'
          onClick={() => handleHelpButtonPress()}
        >
          Help
        </button>
        <button
          className='reset-link text-button'
          onClick={() => handleResetAppButtonPress()}
        >
          Reset App
        </button>
      </div>
    </>
  );
};

export default AppActions;
