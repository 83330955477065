import React from 'react';
import { Route, Link, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useMedia from './hooks/useMedia';
import NavCleanup from './components/common/navCleanup';
import SplashPage from './components/pages/splash/splashPage';
import SetupPage from './components/pages/setup/setupPage';
import DesignPage from './components/pages/design/designPage';
import ReviewPage from './components/pages/review/reviewPage';
import SendPage from './components/pages/send/sendPage';
import ConfirmationPage from './components/pages/confirmation/confirmationPage';
import ReactGA from 'react-ga';
import './styles/main.scss';

const trackingId = 'UA-5790309-38';
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);
// NOTE: Pass this prop to all pages for GO LIVE: reactGA={ReactGA}

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
    <p className='micro-button micro-button--secondary'>
      <Link to='/'>Take me Home</Link>
    </p>
  </div>
);

function App() {
  const mobile = useMedia('(max-width: 1366px)');
  const tablet = useMedia('(min-width: 640px) and (max-width: 1540px)');
  const desktop = useMedia('(min-width: 1367px)');

  function closeMobileMenuDesktop() {
    document.body.classList.remove('mobile-menu-open');
  }

  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  if (isIE) {
    document.body.classList.add('ie11');
  }

  return (
    <>
      <Helmet>
        <script
          type='text/javascript'
          src='//fast.fonts.net/jsapi/27d1bd10-1409-4d83-9c4c-451e87ed1631.js'
        />
      </Helmet>
      <NavCleanup />
      {desktop && closeMobileMenuDesktop()}
      <Switch>
        <Route path='/' exact component={SplashPage} />
        <Route
          path='/setup'
          render={(props) => (
            <SetupPage
              mobile={mobile}
              tablet={tablet}
              desktop={desktop}
              reactGA={ReactGA}
            />
          )}
        />
        <Route
          path='/design'
          render={(props) => (
            <DesignPage
              mobile={mobile}
              tablet={tablet}
              desktop={desktop}
              reactGA={ReactGA}
            />
          )}
        />
        <Route
          path='/review'
          render={(props) => (
            <ReviewPage mobile={mobile} desktop={desktop} reactGA={ReactGA} />
          )}
        />
        <Route
          path='/send'
          render={(props) => (
            <SendPage mobile={mobile} desktop={desktop} reactGA={ReactGA} />
          )}
        />
        <Route
          path='/confirmation'
          render={(props) => <ConfirmationPage reactGA={ReactGA} />}
        />
        <Route component={NoMatch} />
      </Switch>
    </>
  );
}

export default App;
