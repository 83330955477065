/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906

import 'react-app-polyfill/stable';

import React from 'reactn';
import InitiateGlobalState from './components/common/initiateGlobalState';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('app');
const root = createRoot(container);
const appVersion = `${process.env.REACT_APP_VERSION}`;

function CheckLS() {
  const fromLS = sessionStorage.getItem('jcc-global-state');
  let localDataSetup = false;
  let localToPass = {};

  if (typeof fromLS === 'string') {
    let tempLS = JSON.parse(fromLS);

    if (typeof tempLS === 'object') {
      if (tempLS.appversion === appVersion) {
        localDataSetup = true;
      }
      localToPass = JSON.parse(JSON.stringify(fromLS));
    }
  }
  InitiateGlobalState(false, localDataSetup, localToPass, appVersion);
}

CheckLS();

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
