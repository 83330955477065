import React, { useGlobal, useState } from 'reactn';
import { Link } from 'react-router-dom';
import ScrollToElement from '../../common/scrollToElement';

const SendPageContent = (props) => {
  const { mobile, desktop } = props;
  const [zonesToSend] = useGlobal('zonesToSend');
  const [tradeDiscount] = useGlobal('tradeDiscount');
  const [agreedPrivacy, setAgreedPrivacy] = useState(false);
  const [agreedContact, setAgreedContact] = useState(false);
  const [agreedGDPR, setAgreedGDPR] = useState(false);
  const [disabledSendClick, setDisabledSendClick] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [setupFieldChoices] = useGlobal('setupFieldChoices');
  const sendFailure = false;
  const [customerName, setCustomerName] = useState(setupFieldChoices.name);
  const [customerNameError, setCustomerNameError] = useState(false);
  const [customerEmail, setCustomerEmail] = useState(setupFieldChoices.email);
  const [customerEmailError, setCustomerEmailError] = useState(false);
  
  const [customerType, setCustomerType] = useState('none-selected');
  const [customerTypeError, setCustomerTypeError] = useState(false);

  const [customerTypeOther, setCustomerTypeOther] = useState('');
  const [customerTypeOtherError, setCustomerTypeOtherError] = useState(false);
  const [showCustomerTypeOther, setShowCustomerTypeOther] = useState(false);

  const [secondaryEmail, setSecondaryEmail] = useState(setupFieldChoices.email);
  const [secondaryEmailError, setSecondaryEmailError] = useState(false);
  const [customerPhone, setCustomerPhone] = useState(setupFieldChoices.phone);
  const [customerPhoneError, setCustomerPhoneError] = useState(false);
  const [customerNote, setCustomerNote] = useState(setupFieldChoices.note);
  const [customerNoteError, setCustomerNoteError] = useState(false);

  async function sendAndReturn(order) {
    const url = `/.netlify/functions/processorder_v2`;
    try {
      const response = await fetch(url, {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(order),
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  function sendOrder() {
    let newSetupFieldChoices = { ...setupFieldChoices };
    newSetupFieldChoices.name = customerName;
    newSetupFieldChoices.email = customerEmail;
    newSetupFieldChoices.secondaryemail = isEmail(secondaryEmail)
      ? secondaryEmail
      : '';
    newSetupFieldChoices.phone = customerPhone;
    newSetupFieldChoices.note = customerNote.substring(0, 2048);

    let OrderToSend = JSON.parse(JSON.stringify(newSetupFieldChoices));

    OrderToSend.zones = zonesToSend;
    OrderToSend.tradeDiscount = tradeDiscount;

    if(customerType === 'other') {
      OrderToSend.customerType = customerTypeOther;
    } else {
      if(customerType === 'electrician') {
        OrderToSend.customerType = 'Electrician';
      }
      if(customerType === 'electrical-wholesaler') {
        OrderToSend.customerType = 'Electrical Wholesaler';
      }
      if(customerType === 'home-owner') {
        OrderToSend.customerType = 'Home owner/occupier';
      }
      if(customerType === 'jcc-employee') {
        OrderToSend.customerType = 'JCC Employee';
      }
    }

    // console.log(OrderToSend);

    const orderResult = sendAndReturn(OrderToSend);
    // console.log(orderResult);

  }

  function handleAgreedPrivacyClicked() {
    setAgreedPrivacy(!agreedPrivacy);
  }

  function handleAgreedContactClicked() {
    setAgreedContact(!agreedContact);
  }

  function handleDisabledSendClick() {
    setDisabledSendClick(true);
  }

  function handleAgreedGDPRClicked() {
    setAgreedGDPR(!agreedGDPR);
  }

  function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  function handleOnBlur() {

    // console.log('customerName: ', customerName);
    // console.log('customerNameError: ', customerNameError);
    // console.log('customerEmail: ', customerEmail);
    // console.log('customerEmailError: ', customerEmailError);
    // console.log('customerType: ', customerType);
    // console.log('customerTypeError: ', customerTypeError);
    // console.log('customerTypeOther: ', customerTypeOther);
    // console.log('secondaryEmail: ', secondaryEmail);
    // console.log('secondaryEmailError: ', secondaryEmailError);
    // console.log('customerPhone: ', customerPhone);
    // console.log('customerPhoneError: ', customerPhoneError);
    // console.log('customerNote: ', customerNote);
    // console.log('customerNoteError: ', customerNoteError);
    // console.log('agreedPrivacy: ', agreedPrivacy);
    // console.log('agreedContact: ', agreedContact);
    // console.log('agreedGDPR: ', agreedGDPR);
    // console.log('Can I send?', canSend);
    // console.log('==========================================')

    var OKtosend = false;

    if (customerName.length > 2 && !customerNameError && customerEmail.length > 3 && !customerEmailError && customerType != 'none-selected' && !customerTypeError && !secondaryEmailError && agreedPrivacy && agreedGDPR) {
      OKtosend = true;
    }

    if(customerType === 'other' && customerTypeOther.length < 3) {
      OKtosend = false;
    } 

    if(OKtosend && !canSend) {
      setCanSend(true);
      setDisabledSendClick(false);
    }

    if(!OKtosend && canSend){
      setCanSend(false);
      setDisabledSendClick(true);
    }

  }

  function handleCustomerNameChange(input) {
    let newValue = input.target.value;
    setCustomerName(newValue);

    if (newValue.length < 2) {
      setCustomerNameError(true);
    } else {
      setCustomerNameError(false);
    }
  }

  function handleCustomerEmailChange(input) {
    let newValue = input.target.value;
    setCustomerEmail(newValue);

    if (!isEmail(newValue)) {
      setCustomerEmailError(true);
    } else {
      setCustomerEmailError(false);
    }
  }

  function handleCustomerTypeChange(input) {
    let newValue = input.target.value;
    setCustomerType(newValue);

    if(newValue=='other') {
      setShowCustomerTypeOther(true);
      setCustomerTypeOtherError(true);
    } else {
      setShowCustomerTypeOther(false);
      setCustomerTypeOtherError(false);
      setCustomerTypeOther('');
    }

    if(newValue=='none-selected') {
      setCustomerTypeError(true);
    } else {
      setCustomerTypeError(false);
    }
  }

  function handleCustomerTypeOtherChange(input) {
    let newValue = input.target.value;
    setCustomerTypeOther(newValue.substring(0, 50));

    if(customerType === 'other') {
        if (newValue.length < 3) {
          setCustomerTypeOtherError(true);
        } else {
          setCustomerTypeOtherError(false);
        }
    } else {
      setCustomerTypeOtherError(false);
    }

  }

  function handleSecondaryEmailChange(input) {
    let newValue = input.target.value;
    setSecondaryEmail(newValue);

    if (newValue === '') {
      setSecondaryEmailError(false);
    } else {
      if (!isEmail(newValue)) {
        setSecondaryEmailError(true);
      } else {
        setSecondaryEmailError(false);
      }
    }

  }

  function handleCustomerPhoneChange(input) {
    let newValue = input.target.value;
    setCustomerPhone(newValue);
    setCustomerPhoneError(false);
  }

  function handleCustomerNoteChange(input) {
    let newValue = input.target.value;
    setCustomerNote(newValue);
    if (customerNote.length > 2048) {
      setCustomerNoteError(true);
    } else {
      setCustomerNoteError(false);
    }
  }

  function handleScrollToElement(e, fieldID) {
    e.preventDefault();
    e.stopPropagation();
    if (e.detail > 0) {
      ScrollToElement(fieldID, mobile, 200);
    }
  }

  return (
    <>
      <div className='page-content__section'>
        <div className='field-set' key='field-set-1'>
          <form id='send-form'>
            <div className='field current' id='your-details'>
              <div className='field__content-wrapper'>
                {desktop && (
                  <div className='field__nav'>
                    <div className='field__nav__prev' disabled='disabled'>
                      &lt;
                    </div>
                    <div className='field__number'>1</div>
                    <button
                      className='field__nav__next'
                      onClick={(e) =>
                        handleScrollToElement(e, 'send-your-design-summary')
                      }
                    >
                      &gt;
                    </button>
                  </div>
                )}
                <div className='field__block'>
                  <div className='field__header'>
                    {mobile && <div className='field__number'>1</div>}
                    <h2 className='field__title'>Enter your details</h2>
                    <p className='field__instruction'>
                      Enter your details to receive your LED Strip kit design by
                      email. Orders are placed directly with the wholesaler –
                      simply present the email to your wholesaler to request a
                      quote or place an order.
                    </p>
                  </div>
                  <div className='field__content'>
                    <label className='input-label' htmlFor='customer-name'>
                      Your name
                    </label>
                    <input
                      id='customer-name'
                      className={
                        customerNameError ? 'input input__failure' : 'input'
                      }
                      type='text'
                      value={customerName}
                      placeholder='Enter your name here…'
                      tabIndex='1'
                      onChange={(input) => {
                        handleCustomerNameChange(input);
                      }}
                      onBlur={handleOnBlur()}
                    />
                    {customerNameError && (
                      <>
                        <span className='input__failure' />
                        <div
                          id='customer-name-error'
                          className='field__message'
                        >
                          <p className='failure-message'>
                            Name is a required field.
                          </p>
                        </div>
                      </>
                    )}
                    <label className='input-label' htmlFor='customer-email'>
                      Your email address
                    </label>
                    <small>
                      You will receive a copy of your LED Strip design by email.
                    </small>
                    <input
                      id='customer-email'
                      className={
                        customerEmailError ? 'input input__failure' : 'input'
                      }
                      type='text'
                      value={customerEmail}
                      placeholder='Enter your email here…'
                      tabIndex='2'
                      onChange={(input) => {
                        handleCustomerEmailChange(input);
                      }}
                    />
                    {customerEmailError && (
                      <>
                        {' '}
                        <span className='input__failure' />
                        <div
                          id='customer-email-error'
                          className='field__message'
                        >
                          <p className='failure-message'>
                            Email address is a required field.
                          </p>
                        </div>
                      </>
                    )}
                    <label className='select-label' htmlFor='customer-type'>
                      Which best describes you?
                    </label>
                    <select
                      name="customer-type"
                      className='select'
                      id="customer-type"
                      tabIndex='3'
                      value={customerType}
                      onChange={(input) => {
                        handleCustomerTypeChange(input);
                      }}
                      style={{ color: customerType==='none-selected' ? "lightgray" : "inherit"}}>
                      <option value="none-selected" default>Select a customer type...</option>
                      <option value="electrician">Electrician</option>
                      <option value="electrical-wholesaler">Electrical Wholesaler</option>
                      <option value="home-owner">Home owner/occupier</option>
                      <option value="jcc-employee">JCC Employee</option>
                      <option value="other">Other (please specify)</option>
                    </select>
                    {customerTypeError && (
                      <>
                        {' '}
                        <span className='select__failure' />
                        <div
                          id='customer-type-error'
                          className='field__message'
                        >
                          <p className='failure-message'>
                            Please make a selection (and complete the input field if the choice is Other).
                          </p>
                        </div>
                      </>
                    )}
                    {(showCustomerTypeOther && <input
                      id='customer-type-other'
                      className={
                        customerTypeOtherError ? 'input input__failure' : 'input'
                      }
                      type='text'
                      value={customerTypeOther}
                      placeholder='Please specify what best describes you...'
                      tabIndex='4'
                      style={{ marginTop:20}}
                      onChange={(input) => {
                        handleCustomerTypeOtherChange(input);
                      }}
                    /> )}
                    {customerTypeOtherError && (
                      <>
                        <span className='input__failure' />
                        <div
                          id='customer-type-other-error'
                          className='field__message'
                        >
                          <p className='failure-message'>
                            This is a required field and must be more than 2 characters in length.
                          </p>
                        </div>
                      </>
                    )}
                    <label className='input-label' htmlFor='secondary-email'>
                      Secondary email address (optional)
                    </label>
                    <small>
                      You can specify a secondary email recipient to receive a
                      copy of your LED Strip design.
                    </small>
                    <input
                      id='secondary-email'
                      className={
                        secondaryEmailError ? 'input input__failure' : 'input'
                      }
                      type='text'
                      value={secondaryEmail}
                      placeholder='Enter secondary email here…'
                      tabIndex='5'
                      onChange={(input) => {
                        handleSecondaryEmailChange(input);
                      }}
                    />
                    {secondaryEmailError && (
                      <>
                        {' '}
                        <span className='input__failure' />
                        <div
                          id='secondary-email-error'
                          className='field__message'
                        >
                          <p className='failure-message'>
                            Please enter a valid email address or clear this
                            field.
                          </p>
                        </div>
                      </>
                    )}
                    <label className='input-label' htmlFor='customer-phone'>
                      Your phone number (optional)
                    </label>
                    <small>
                      Add your phone number if you would like your wholesaler to
                      be able to contact you direct to discuss your design.
                    </small>
                    <input
                      id='customer-phone'
                      className={
                        customerPhoneError ? 'input input__failure' : 'input'
                      }
                      type='text'
                      value={customerPhone}
                      placeholder='Your phone number'
                      tabIndex='6'
                      onChange={(input) => {
                        handleCustomerPhoneChange(input);
                      }}
                    />
                    {customerPhoneError && (
                      <>
                        <span className='input__failure' />
                        <div
                          id='customer-phone-error'
                          className='field__message'
                        >
                          <p className='failure-message'>
                            Phone number is a required field.
                          </p>
                        </div>
                      </>
                    )}
                    <label className='input-label' htmlFor='customer-note'>
                      Add note to wholesaler (optional)
                    </label>
                    <small>
                      Use this field to provide instructions to the wholesaler
                      regarding any unique elements of the installation.
                    </small>
                    <textarea
                      id='customer-note'
                      className={
                        customerNoteError ? 'input input__failure' : 'input'
                      }
                      rows='6'
                      value={customerNote}
                      placeholder='Add your notes here…'
                      tabIndex='7'
                      onChange={(input) => {
                        handleCustomerNoteChange(input);
                      }}
                    />
                    {customerNoteError && (
                      <>
                        <span className='input__failure' />
                        <div
                          id='customer-note-error'
                          className='field__message'
                        >
                          <p className='failure-message'>
                            Note must be less than 2048 characters.
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className='field field--slim current'
              id='send-your-design-summary'
            >
              <div className='field__content-wrapper'>
                {desktop && (
                  <div className='field__nav'>
                    <button
                      className='field__nav__prev'
                      onClick={(e) => handleScrollToElement(e, 'your-details')}
                    >
                      &lt;
                    </button>
                    <div className='field__number'>2</div>
                    <div className='field__nav__next' disabled='disabled'>
                      &gt;
                    </div>
                  </div>
                )}
                <div className='field__block'>
                  <div className='field__header'>
                    {mobile && <div className='field__number'>2</div>}
                    <h2 className='field__title'>Send your design summary</h2>
                    <p className='field__description'>
                      You will receive a copy of your kit design summary by
                      email. Please present the summary email to your wholesaler
                      to request a quote or place an order.
                    </p>
                  </div>
                  <div className='field__content'>
                    <div className='field__checkbox'>
                      <input
                        type='checkbox'
                        id='data-usage'
                        name='data-usage'
                        tabIndex='8'
                        agreed={agreedPrivacy.toString()}
                        onClick={() => handleAgreedPrivacyClicked()}
                      />
                      <label htmlFor='data-usage' />I consent to my details
                      being retained by JCC in accordance with its{' '}
                      <a
                        href='https://www.jcc.co.uk/en_GB/jcc-privacy-policy'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        privacy policy
                      </a>
                      . My details will only be used for purposes of creating
                      the LED Strip kit design and will not be used by JCC for
                      marketing purposes or shared with third parties.{' '}
                      <span className='required active'>*</span>
                    </div>
                    <div className='field__checkbox'>
                      <input
                        type='checkbox'
                        id='contact-consent'
                        name='contact-consent'
                        tabIndex='9'
                        agreed={agreedContact.toString()}
                        onClick={() => handleAgreedContactClicked()}
                      />
                      <label htmlFor='contact-consent' />I consent to JCC
                      contacting me in relation to the LED Strip kit design
                      through the details provided above.
                    </div>
                    <div className='field__checkbox'>
                      <input
                        type='checkbox'
                        id='gdpr-compliance'
                        name='gdpr-compliance'
                        tabIndex='10'
                        agreed={agreedGDPR.toString()}
                        onClick={() => handleAgreedGDPRClicked()}
                      />
                      <label htmlFor='gdpr-compliance' />I have read and
                      understand the{' '}
                      <a
                        href='https://www.jcc.co.uk/en_GB/jcc-privacy-policy'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        privacy policy
                      </a>
                      . <span className='required active'>*</span>
                    </div>

                    {!(agreedPrivacy && agreedGDPR) &&
                      disabledSendClick && (
                        <small className='consent-alert active'>
                          <strong>*</strong> The checkboxes marked with an
                          asterisk above are required before you can send your
                          design.
                        </small>
                      )}

                    {sendFailure && (
                      <div
                        className='form-notice form-notice--failure'
                        style={{ display: 'none' }}
                        id='form-failure-message'
                      >
                        <p className='lead'>
                          Your form could not be sent at this time.
                        </p>
                        <p>
                          Please check your connection and press{' '}
                          <strong>SEND</strong> again to retry.
                        </p>
                      </div>
                    )}
                    <div className='button-group'>
                      {canSend ? (
                        <Link
                          className='button button--tertiary button--next-page'
                          to='/confirmation'
                          onClick={() => sendOrder()}
                        >
                          Send your design
                        </Link>
                      ) : (
                        <div
                          className='button button--tertiary button--next-page'
                          disabled
                          onClick={() => handleDisabledSendClick()}
                        >
                          Send your design
                        </div>
                      )}
                    </div>
                    {!canSend && disabledSendClick && (
                        <small className='consent-alert active'>
                          <strong>Unable to submit.</strong> Please ensure that all required fields have valid inputs and required checkboxes are selected.
                        </small>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SendPageContent;