import React, { useGlobal } from 'reactn';
import { Slide } from 'pure-react-carousel';
import classNames from 'classnames';
import DesignCardInput from './designCardInput';
import useKeyPress from '../../../hooks/useKeyPress';

const DesignFieldCardZones = (props) => {
  const { zoneIndex, layoutIndex, fieldIndex, fieldItemIndex } = props;
  const [zones, setZones] = useGlobal('zones');

  function handleInputItemEntrySuccess(newValue) {
    let newZones = zones.slice();
    const numFields = newZones[zoneIndex].layouts[layoutIndex].fields.length;
    const newVal = newValue;

    newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].length = newVal;
    newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].quantity =
      newVal /
      newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
        fieldItemIndex
      ].validationdivisor;

    newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
      fieldItemIndex
    ].length = newVal;
    newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].price =
      Math.ceil(
        newVal /
          newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
            fieldItemIndex
          ].validationdivisor
      ) *
      newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
        fieldItemIndex
      ].price;

    // Update strip length and length remaining for layout

    if (
      newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].title ===
      'Straight LED Strip'
    ) {
      let newStripLength = 0;

      for (let i = 0; i < numFields; i++) {
        if (
          newZones[zoneIndex].layouts[layoutIndex].fields[i].title ===
          'Straight LED Strip'
        ) {
          newStripLength =
            newStripLength +
            newZones[zoneIndex].layouts[layoutIndex].fields[i].length;
        }
      }

      newZones[zoneIndex].layouts[layoutIndex].stripLength = newStripLength;
      newZones[zoneIndex].layouts[layoutIndex].lengthRemaining =
        newZones[zoneIndex].layouts[layoutIndex].maxLength -
        newZones[zoneIndex].layouts[layoutIndex].stripLength;

      newZones[zoneIndex].layouts[layoutIndex].fields[
        fieldIndex
      ].validationlimit =
        newZones[zoneIndex].layouts[layoutIndex].lengthRemaining;
      newZones[zoneIndex].layouts[layoutIndex].fields[
        fieldIndex
      ].showendlayoutbutton = true;
    }

    newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].shorttitle =
      newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
        fieldItemIndex
      ].title;

    newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
      fieldItemIndex
    ].selected = true;
    newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
      fieldItemIndex
    ].disabled = false;
    newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
      fieldItemIndex
    ].showinput = false;
    document.body.classList.remove('input-overlay-active');

    if (newZones[zoneIndex].layouts[layoutIndex].lengthRemaining > 0) {
      newZones[zoneIndex].layouts[layoutIndex].fields[
        fieldIndex
      ].showaddnextpiecebutton = true;
    }

    setZones(newZones);
  }

  // Handle Escape key press
  const escPress = useKeyPress('Escape');
  if (
    escPress &&
    zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
      fieldItemIndex
    ].showinput
  ) {
    let newZones = zones.slice();

    if (
      newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
        fieldItemIndex
      ].type === 'input'
    ) {
      newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
        fieldItemIndex
      ].showinput = false;
      document.body.classList.remove('input-overlay-active');

      if (
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].length === 0
      ) {
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
          fieldItemIndex
        ].selected = false;
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
          fieldItemIndex
        ].deselected = true;
      } else {
        if (newZones[zoneIndex].layouts[layoutIndex].lengthRemaining > 0) {
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showaddnextpiecebutton = true;
        } else {
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showaddnextpiecebutton = false;
        }

        if (
          newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].title ===
          'Straight LED Strip'
        ) {
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showendlayoutbutton = true;
        } else {
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showendlayoutbutton = false;
        }
      }
    }

    setZones(newZones);
  }

  function handleInputOverlayClose() {
    let newZones = zones.slice();

    if (
      newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
        fieldItemIndex
      ].type === 'input'
    ) {
      newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
        fieldItemIndex
      ].showinput = false;
      document.body.classList.remove('input-overlay-active');

      if (
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].length === 0
      ) {
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
          fieldItemIndex
        ].selected = false;
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
          fieldItemIndex
        ].deselected = true;
      } else {
        if (newZones[zoneIndex].layouts[layoutIndex].lengthRemaining > 0) {
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showaddnextpiecebutton = true;
        } else {
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showaddnextpiecebutton = false;
        }

        if (
          newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].title ===
          'Straight LED Strip'
        ) {
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showendlayoutbutton = true;
        } else {
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showendlayoutbutton = false;
        }
      }
    }

    setZones(newZones);
  }

  function displayItemContent(fieldItem) {
    if (
      fieldItem.type === 'input' &&
      fieldItem.showinput &&
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].current
    ) {
      document.body.classList.add('input-overlay-active');
      return (
        <>
          <DesignCardInput
            fieldItem={fieldItem}
            enterPressed={handleInputItemEntrySuccess}
          />
          <button
            className='input-overlay-close'
            onClick={() => handleInputOverlayClose()}
          />
          <img
            className=''
            src={require('../../../graphics/' + fieldItem.imagepath)}
            alt={fieldItem.title}
          />
        </>
      );
    } else {
      return (
        <>
          <img
            className=''
            src={require('../../../graphics/' + fieldItem.imagepath)}
            alt={fieldItem.title}
          />
        </>
      );
    }
  }

  function handleItemClicked(item) {
    let newZones = JSON.parse(JSON.stringify(zones));
    const numFields = newZones[zoneIndex].layouts[layoutIndex].fields.length;

    if (
      newZones[zoneIndex].layouts[layoutIndex].fields[numFields - 1].current &&
      !newZones[zoneIndex].layouts[layoutIndex].complete
    ) {
      const numOptions =
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options
          .length;

      if (
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
          fieldItemIndex
        ].type === 'input'
      ) {
        if (
          !newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
            fieldItemIndex
          ].showinput
        ) {
          newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
            fieldItemIndex
          ].showinput = true;
          newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
            fieldItemIndex
          ].selected = true;
          newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
            fieldItemIndex
          ].deselected = false;

          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showaddnextpiecebutton = false;
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showendlayoutbutton = false;

          newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].value =
            item.title;

          for (let i = 0; i < numOptions; i++) {
            if (i !== fieldItemIndex) {
              newZones[zoneIndex].layouts[layoutIndex].fields[
                fieldIndex
              ].options[i].selected = false;
              newZones[zoneIndex].layouts[layoutIndex].fields[
                fieldIndex
              ].options[i].deselected = true;

              if (
                newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                  .options[i].type === 'input'
              ) {
                newZones[zoneIndex].layouts[layoutIndex].fields[
                  fieldIndex
                ].options[i].showinput = false;
              }
            }
          }

          setZones(newZones);
          return;
        }
      } else {
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
          fieldItemIndex
        ].showinput = false;
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].length = 0;

        for (let i = 0; i < numOptions; i++) {
          if (i !== fieldItemIndex) {
            newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
              i
            ].selected = false;
            newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
              i
            ].deselected = true;

            if (
              newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                .options[i].type === 'input'
            ) {
              newZones[zoneIndex].layouts[layoutIndex].fields[
                fieldIndex
              ].options[i].showinput = false;
              newZones[zoneIndex].layouts[layoutIndex].fields[
                fieldIndex
              ].options[i].length = 0;
            }
          }
        }
      }

      if (fieldIndex === 0) {
        newZones[zoneIndex].layouts[layoutIndex].fields[0].current = false;

        if (
          newZones[zoneIndex].layouts[layoutIndex].fields[0].options.length > 1
        ) {
          newZones[zoneIndex].layouts[layoutIndex].fields[0].current = true;
        }
        return;
      }

      if (item.title === 'DC Cable Corner' && item.selected) {
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].length = 50;
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
          fieldItemIndex
        ].length = 50;
      }

      if (!item.selected) {
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
          fieldItemIndex
        ].selected = true;
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
          fieldItemIndex
        ].deselected = false;
        newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].value =
          item.title;

        for (let i = 0; i < numFields; i++) {
          if (!i === fieldIndex) {
            newZones[zoneIndex].layouts[layoutIndex].fields[
              fieldIndex
            ].current = false;
          }
        }

        for (let i = 0; i < numOptions; i++) {
          if (i !== fieldItemIndex) {
            newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
              i
            ].selected = false;
            newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
              i
            ].deselected = true;

            if (
              newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                .options[i].type === 'input'
            ) {
              newZones[zoneIndex].layouts[layoutIndex].fields[
                fieldIndex
              ].options[i].showinput = false;
            }
          }
        }

        if (item.title === 'DC Cable Corner') {
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].length = 50;
          newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
            fieldItemIndex
          ].length = 50;
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].quantity = 1;
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].shorttitle = 'DC Cable Corner';
          newZones[zoneIndex].layouts[layoutIndex].fields[
            fieldIndex
          ].showaddnextpiecebutton = true;
          newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].price =
            newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
              fieldItemIndex
            ].price;
        }
      }

      setZones(newZones);
    }
  }

  const cardClass = classNames({
    card: true,
    selected:
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
        fieldItemIndex
      ].selected,
    deselected:
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
        fieldItemIndex
      ].deselected,
  });

  return (
    <>
      <Slide
        key={
          zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
            fieldItemIndex
          ].id
        }
        index={fieldItemIndex}
        onClick={
          zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
            fieldItemIndex
          ].type === 'input' &&
          zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
            fieldItemIndex
          ].showinput
            ? () => {}
            : () =>
                handleItemClicked(
                  zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                    .options[fieldItemIndex]
                )
        }
      >
        <div className={cardClass}>
          <div className='card__top'>
            <div className='card__status'>
              {zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
                fieldItemIndex
              ].selected && (
                <div className='card__status__icon card__status__icon--success' />
              )}
              {zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
                fieldItemIndex
              ].length > 0 &&
                !zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                  .options[fieldItemIndex].deselected && (
                  <div className='card__status__value card__status__value--success'>
                    {
                      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                        .options[fieldItemIndex].length
                    }
                  </div>
                )}
            </div>
            {displayItemContent(
              zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].options[
                fieldItemIndex
              ]
            )}
          </div>
          <div className='card__text'>
            <p className='card__title'>
              {
                zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                  .options[fieldItemIndex].title
              }
            </p>
            <p className='card__description'>
              {
                zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                  .options[fieldItemIndex].description
              }
            </p>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default DesignFieldCardZones;
