import smoothscroll from 'smoothscroll-polyfill';

function ScrollToElement(elementID, mobile, delay = 800) {
  if (elementID === 'top') {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
    return;
  }

// console.log('elementID: ' + elementID);

  const element = document.getElementById(elementID);
  smoothscroll.polyfill();

// console.log('element: ' + element);

  if (element) {
    setTimeout(() => {
      window.scrollTo({
        top: element.offsetTop - (mobile ? 64 : 0),
        left: 0,
        behavior: 'smooth'
      });
    }, delay);
  }
}

export default ScrollToElement;
