import React, { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import ScrollToElement from '../../common/scrollToElement';

const ReviewFieldZones = (props) => {
  const { zoneIndex, layoutIndex, mobile, desktop, content } = props;
  const [zones, setZones] = useGlobal('zones');
  const [pageAttributes] = useGlobal('pageAttributes');
  const [setupFields] = useGlobal('setupFields');
  const [setupFieldChoices] = useGlobal('setupFieldChoices');
  const lastFieldID =
    zones[zoneIndex].layouts[layoutIndex].fields[
      zones[zoneIndex].layouts[layoutIndex].fields.length - 1
    ].id;

  const withRemote = setupFieldChoices.remoteQuantity > 0;

  const previousFieldID = 'zone-' + zoneIndex + '-layout-' + layoutIndex;
  const nextFieldID =
    layoutIndex + 1 === zones[zoneIndex].layouts.length
      ? 'ready-to-order'
      : 'zone-' + zoneIndex + '-layout-' + (layoutIndex + 2);

  function handleEditThisLayoutButtonClick() {
    zones[zoneIndex].layouts[layoutIndex].complete = false;
    pageAttributes.designPage.allLayoutsEnded = false;
    pageAttributes.designPage.status = 'incomplete';
    pageAttributes.reviewPage.status = 'design required';
    pageAttributes.sendPage.status = 'design required';

    pageAttributes.designPage.currentFieldID =
      zones[zoneIndex].layouts[layoutIndex].fields[
        zones[zoneIndex].layouts[layoutIndex].fields.length - 1
      ].id;
    pageAttributes.setupPage.singleLength = false;

    for (let zi = 0; zi < zones.length; zi++) {
      for (let li = 0; li < zones[zi].layouts.length; li++) {
        if (zi === parseInt(zoneIndex) && li === parseInt(layoutIndex)) {
          zones[zi].current = true;
          zones[zi].layouts[li].current = true;
        } else {
          zones[zi].current = false;
          zones[zi].layouts[li].current = false;
        }
      }
    }

    zones[zoneIndex].layouts[layoutIndex].fields[
      zones[zoneIndex].layouts[layoutIndex].fields.length - 1
    ].showaddnextpiecebutton = true;
    zones[zoneIndex].layouts[layoutIndex].fields[
      zones[zoneIndex].layouts[layoutIndex].fields.length - 1
    ].showendlayoutbutton = true;
    zones[zoneIndex].layouts[layoutIndex].fields[
      zones[zoneIndex].layouts[layoutIndex].fields.length - 1
    ].showremovepiecebutton = true;

    setZones(zones);

    setupFields[setupFields.length - 1].value = 'Custom Design';
    setupFields[setupFields.length - 1].length = 0;
    setupFields[setupFields.length - 1].options[0].selected = true;
    setupFields[setupFields.length - 1].options[0].deselected = false;
    setupFields[setupFields.length - 1].options[1].selected = false;
    setupFields[setupFields.length - 1].options[1].deselected = true;
    setupFields[setupFields.length - 1].options[1].length = 0;
  }

  return (
    <>
      <div
        className='field current'
        id={`zone-` + (zoneIndex + 1) + `-layout-` + (layoutIndex + 1)}
        key={`zone-` + (zoneIndex + 1) + `-layout-` + (layoutIndex + 1)}
      >
        <div className='field__content-wrapper'>
          {desktop && (
            <div className='field__nav'>
              {layoutIndex === 0 && setupFieldChoices.ledType === 'RGBW' && (
                <button
                  className='field__nav__prev'
                  onClick={() => ScrollToElement('rgbw-block', mobile, 200)}
                >
                  &lt;
                </button>
              )}
              {layoutIndex === 0 && setupFieldChoices.ledType === 'White' && (
                <div className='field__nav__prev' disabled>
                  &lt;
                </div>
              )}
              {layoutIndex > 0 && (
                <button
                  className='field__nav__prev'
                  onClick={() => ScrollToElement(previousFieldID, mobile, 200)}
                >
                  &lt;
                </button>
              )}

              <div className='field__number'>{layoutIndex + 1}</div>
              <button
                className='field__nav__next'
                onClick={() => ScrollToElement(nextFieldID, mobile, 200)}
              >
                &gt;
              </button>
            </div>
          )}
          <Waypoint
            onEnter={({ previousPosition, currentPosition, event }) => {
              if (Waypoint.inside) {
                window.history.replaceState(
                  undefined,
                  undefined,
                  '#' + zones[zoneIndex].layouts[layoutIndex].id
                );
              }
            }}
            scrollableAncestor={window}
            fireOnRapidScroll={false}
            topOffset='55%'
            bottomOffset='30%'
            debug={false}
            // key={cursor}
          >
            <div className='field__block'>
              <div className='field__header'>
                {mobile && (
                  <div className='field__number'>{layoutIndex + 1}</div>
                )}
                <h2 className='field__title'>
                  {withRemote
                    ? `Zone ` +
                      (zoneIndex + 1) +
                      `: Layout ` +
                      zones[zoneIndex].layouts[layoutIndex].layoutNumber
                    : `Layout ` +
                      zones[zoneIndex].layouts[layoutIndex].layoutNumber}
                </h2>
                <p className='field__instruction'>
                  {`Here is a summary of the components that will be supplied with this layout. Adjust the quantity below to order multiple identical kits using this layout.`}
                </p>
                {setupFieldChoices.ledType === 'RGBW' &&
                  setupFieldChoices.driverRequired === 'JCC Driver' && (
                    <p className='field__instruction'>
                      Please note that whilst the driver supplied with RGBW kits
                      is non-dimmable, the RGBW strip is dimmable as standard
                      via the Receiver &amp; Remote.
                    </p>
                  )}
              </div>
              <div className='field__content'>
                {content}
                {/* <p style={{ color: 'magenta' }}>
                  Driver SKU: {layouts[layoutIndex].fields[0].SKU}
                  <br />
                  {layouts[layoutIndex].fields.length > 2
                    ? `DC Cable SKU: ` + layouts[layoutIndex].fields[1].SKU
                    : ''}
                  <br />
                  {layouts[layoutIndex].fields.length > 2
                    ? `LED Strip SKU: ` + layouts[layoutIndex].fields[2].SKU
                    : ''}
                </p> */}
                <div className='smallprint'>
                  {setupFieldChoices.driverRequired !== 'JCC Driver' && (
                    <p>
                      <small>
                        It is the responsibility of the installer to ensure a
                        suitable driver based on total tape wattage for each
                        layout is used in the installation. The minimum wattage
                        rating required for this layout is{' '}
                        {Math.ceil(
                          (setupFieldChoices.wattage / 100) *
                            zones[zoneIndex].layouts[layoutIndex].stripLength
                        ) + 'W '}
                        <strong>for each layout</strong>.
                      </small>
                    </p>
                  )}
                  {setupFieldChoices.profile !== 'No Profile' && (
                    <p>
                      <small>
                        Profile is provided to make up the total length of LED
                        Strip, rounded up to the nearest 500mm. If you require an
                        alternate length of profile, please add a note to your
                        wholesaler on the next screen.
                      </small>
                    </p>
                  )}
                  <p>
                    {setupFieldChoices.ledType === 'RGBW' &&
                      setupFieldChoices.environment === 'IP65' && (
                        <small className='warning'>
                          {setupFieldChoices.rgbwIP65warning}
                        </small>
                      )}
                  </p>
                </div>
                <div className='button-group'>
                  <Link
                    className='button button--bordered'
                    to={{
                      pathname: 'design',
                      hash: lastFieldID,
                    }}
                    onClick={() => {
                      handleEditThisLayoutButtonClick();
                    }}
                  >
                    Edit this layout
                  </Link>
                </div>
              </div>
            </div>
          </Waypoint>
        </div>
      </div>
    </>
  );
};

export default ReviewFieldZones;
