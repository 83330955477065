import React, { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';

const PageNav = props => {
  const { mobile, desktop, onMobileNav } = props;

  const [pageAttributes] = useGlobal('pageAttributes');
  const activePage = window.location.pathname;
  const pages = ['setup', 'design', 'review', 'send'];
  const paths = [
    'setup#' + pageAttributes.setupPage.currentFieldID,
    'design#' + pageAttributes.designPage.currentFieldID,
    'review',
    'send'
  ];

  let showPrevButton = true;
  let showNextButton = true;

  if (activePage === '/setup') {
    showPrevButton = false;
    showNextButton = false;
  }
  if (activePage === '/design' && !pageAttributes.designPage.allLayoutsEnded) {
    showPrevButton = false;
  }

  if (activePage === '/design') showNextButton = false;
  if (activePage === '/review') showNextButton = false;

  return (
    <>
      {desktop && (
        <>
          {pages.indexOf(activePage.substr(1, activePage.length)) !== 0 &&
            showPrevButton && (
              <Link
                className='prev-link'
                to={
                  activePage === '/review' &&
                  pageAttributes.setupPage.singleLength
                    ? 'setup#' + pageAttributes.setupPage.currentFieldID
                    : paths[
                        pages.indexOf(activePage.substr(1, activePage.length)) -
                          1
                      ]
                }
              >
                Previous
              </Link>
            )}
          <ul className='breadcrumb'>
            <li className={activePage === '/setup' ? 'current' : ''}>
              <div className='breadcrumb__item'>
                <span className='step-number'>01</span>Setup
              </div>
            </li>
            <li className={activePage === '/design' ? 'current' : ''}>
              <div className='breadcrumb__item'>
                <span className='step-number'>02</span>Design
              </div>
            </li>
            <li className={activePage === '/review' ? 'current' : ''}>
              <div className='breadcrumb__item'>
                <span className='step-number'>03</span>Review
              </div>
            </li>
            {activePage === '/send' ? (
              <li className='current'>
                <div className='breadcrumb__item'>
                  <span className='step-number'>04</span>Send
                </div>
              </li>
            ) : (
              <li>
                <div className='breadcrumb__item'>
                  <span className='step-number'>04</span>Send
                </div>
              </li>
            )}
          </ul>
          {pages.indexOf(activePage.substr(1, activePage.length)) !==
            pages.length - 1 &&
            showNextButton && (
              <Link
                className='next-link'
                to={
                  activePage === '/setup' &&
                  pageAttributes.setupPage.singleLength
                    ? 'review'
                    : activePage === '/setup'
                    ? 'design'
                    : paths[
                        pages.indexOf(activePage.substr(1, activePage.length)) +
                          1
                      ]
                }
              >
                Next
              </Link>
            )}
        </>
      )}
      {mobile && (
        <>
          <ul className='breadcrumb'>
            <li className={activePage === '/setup' ? 'current' : ''}>
              <Link
                className='breadcrumb__item'
                to={{
                  pathname: 'setup',
                  hash: pageAttributes.setupPage.currentFieldID
                }}
                onClick={
                  activePage === '/setup' ? () => onMobileNav() : () => {}
                }
              >
                <span className='step-number'>01</span>Setup
                <span
                  className={
                    pageAttributes.setupPage.status === 'complete' &&
                    activePage === '/setup'
                      ? 'breadcrumb__item__status complete'
                      : pageAttributes.setupPage.status === 'incomplete' &&
                        activePage === '/setup'
                      ? 'breadcrumb__item__status incomplete'
                      : 'breadcrumb__item__status'
                  }
                >
                  {pageAttributes.setupPage.status}
                </span>
              </Link>
            </li>
            <li className={activePage === '/design' ? 'current' : ''}>
              {activePage !== '/design' &&
              (pageAttributes.designPage.status === 'complete' ||
                pageAttributes.designPage.status === 'incomplete') ? (
                <Link
                  className='breadcrumb__item'
                  to={{
                    pathname: 'design',
                    hash: ''
                  }}
                  onClick={
                    activePage === '/design' ? () => onMobileNav() : () => {}
                  }
                >
                  <span className='step-number'>02</span>Design
                  <span
                    className={
                      pageAttributes.designPage.status === 'complete' &&
                      activePage === '/design'
                        ? 'breadcrumb__item__status complete'
                        : pageAttributes.designPage.status === 'incomplete' &&
                          activePage === '/design'
                        ? 'breadcrumb__item__status incomplete'
                        : 'breadcrumb__item__status'
                    }
                  >
                    {pageAttributes.designPage.status}
                  </span>
                </Link>
              ) : (
                <div
                  className='breadcrumb__item'
                  onClick={
                    activePage === '/design' ? () => onMobileNav() : () => {}
                  }
                >
                  <span className='step-number'>02</span>Design
                  <span
                    className={
                      pageAttributes.designPage.status === 'complete' &&
                      activePage === '/design'
                        ? 'breadcrumb__item__status complete'
                        : pageAttributes.designPage.status === 'incomplete' &&
                          activePage === '/design'
                        ? 'breadcrumb__item__status incomplete'
                        : 'breadcrumb__item__status'
                    }
                  >
                    {pageAttributes.designPage.status}
                  </span>
                </div>
              )}
            </li>
            <li className={activePage === '/review' ? 'current' : ''}>
              {activePage !== '/review' &&
              (pageAttributes.reviewPage.status === 'complete' ||
                pageAttributes.reviewPage.status === 'incomplete') ? (
                <Link
                  className='breadcrumb__item'
                  to={{
                    pathname: 'review',
                    hash: ''
                  }}
                  onClick={
                    activePage === '/review' ? () => onMobileNav() : () => {}
                  }
                >
                  <span className='step-number'>03</span>Review
                  <span
                    className={
                      pageAttributes.reviewPage.status === 'complete' &&
                      activePage === '/review'
                        ? 'breadcrumb__item__status complete'
                        : pageAttributes.reviewPage.status === 'incomplete' &&
                          activePage === '/review'
                        ? 'breadcrumb__item__status incomplete'
                        : 'breadcrumb__item__status'
                    }
                  >
                    {pageAttributes.reviewPage.status}
                  </span>
                </Link>
              ) : (
                <div
                  className='breadcrumb__item'
                  onClick={
                    activePage === '/review' ? () => onMobileNav() : () => {}
                  }
                >
                  <span className='step-number'>03</span>Review
                  <span
                    className={
                      pageAttributes.reviewPage.status === 'complete' &&
                      activePage === '/review'
                        ? 'breadcrumb__item__status complete'
                        : pageAttributes.reviewPage.status === 'incomplete' &&
                          activePage === '/review'
                        ? 'breadcrumb__item__status incomplete'
                        : 'breadcrumb__item__status'
                    }
                  >
                    {pageAttributes.reviewPage.status}
                  </span>
                </div>
              )}
            </li>
            <li className={activePage === '/send' ? 'current' : ''}>
              <div
                className='breadcrumb__item'
                onClick={
                  activePage === '/send' ? () => onMobileNav() : () => {}
                }
              >
                <span className='step-number'>04</span>Send
                <span
                  className={
                    pageAttributes.sendPage.status === 'complete' &&
                    activePage === '/send'
                      ? 'breadcrumb__item__status complete'
                      : pageAttributes.sendPage.status === 'incomplete' &&
                        activePage === '/send'
                      ? 'breadcrumb__item__status incomplete'
                      : 'breadcrumb__item__status'
                  }
                >
                  {pageAttributes.sendPage.status}
                </span>
              </div>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default PageNav;
