import React, { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ScrollHandler from './scrollHandler';

const LayoutTimelineZones = (props) => {
  const { zoneIndex, layoutIndex, timelineItems, noLinks } = props;
  const [zones, setZones] = useGlobal('zones');

  function handleTimelineItemClick(timelineItemID) {
    let newZones = JSON.parse(JSON.stringify(zones));

    let numFieldsInThisLayout =
      newZones[zoneIndex].layouts[layoutIndex].fields.length;
    for (let i = 0; i < numFieldsInThisLayout; i++) {
      if (i === numFieldsInThisLayout - 1) {
        if (
          newZones[zoneIndex].layouts[layoutIndex].fields[i].id ===
          timelineItemID
        ) {
          newZones[zoneIndex].layouts[layoutIndex].fields[i].current = true;
        } else {
          newZones[zoneIndex].layouts[layoutIndex].fields[i].current = false;
        }
      }
    }
    setZones(newZones);
    return null;
  }

  return (
    <>
      <ScrollHandler />
      <div className='timeline'>
        <ul className='timeline__items'>
          {timelineItems.map(
            (timelineItem, timelineItemIndex) =>
              !timelineItem.disabled && (
                <li
                  key={timelineItem.id + `-number`}
                  className={classNames({
                    timeline__item: true,
                    current:
                      timelineItem.current ||
                      window.location.hash === '#' + timelineItem.id,
                    disabled: timelineItem.disabled,
                  })}
                >
                  <div className='timeline__item__number'>
                    {!noLinks && (
                      <Link
                        to={`#${timelineItem.id}`}
                        className='timeline__item__link'
                        onClick={() => {
                          handleTimelineItemClick(timelineItem.id);
                        }}
                      >
                        {timelineItem.icon
                          ? timelineItem.icon
                          : timelineItemIndex + 1}
                      </Link>
                    )}
                    {noLinks && (
                      <div className='timeline__item__link'>
                        {timelineItem.icon
                          ? timelineItem.icon
                          : timelineItemIndex + 1}
                      </div>
                    )}
                  </div>
                  <div className='timeline__item__data'>
                    <span className='timeline__item__data__key'>
                      {timelineItem.shorttitle
                        ? timelineItem.shorttitle
                        : timelineItem.title}
                    </span>
                    <span className='timeline__item__data__value'>
                      {timelineItem.length
                        ? timelineItem.length + 'mm'
                        : timelineItem.value
                        ? timelineItem.value
                        : 'Choose an option'}
                    </span>
                  </div>
                </li>
              )
          )}
        </ul>
      </div>
    </>
  );
};

export default LayoutTimelineZones;
