import React, { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ScrollHandler from './scrollHandler';

const Timeline = (props) => {
  const { timelineItems, noLinks, mobile } = props;
  const [setupFieldChoices] = useGlobal('setupFieldChoices');

  let rgbwRemoteMulitpleLabel = '';

  if (setupFieldChoices.remoteQuantity === 2) {
    rgbwRemoteMulitpleLabel = ' x 2';
  }

  return (
    <>
      <ScrollHandler mobile={mobile} />
      <div className='timeline'>
        <ul className='timeline__items'>
          {timelineItems.map(
            (timelineItem, timelineItemIndex) =>
              !timelineItem.disabled && (
                <li
                  key={timelineItem.id + `-number`}
                  className={classNames({
                    timeline__item: true,
                    current: timelineItem.current,
                    disabled: timelineItem.disabled,
                  })}
                >
                  <div className='timeline__item__number'>
                    {!noLinks && (
                      <Link
                        to={`#${timelineItem.id}`}
                        className='timeline__item__link'
                      >
                        {timelineItem.icon
                          ? timelineItem.icon
                          : timelineItemIndex + 1}
                      </Link>
                    )}
                    {noLinks && (
                      <div className='timeline__item__link'>
                        {timelineItem.icon
                          ? timelineItem.icon
                          : timelineItemIndex + 1}
                      </div>
                    )}
                  </div>
                  <div className='timeline__item__data'>
                    <span className='timeline__item__data__key'>
                      {timelineItem.shorttitle
                        ? timelineItem.shorttitle
                        : timelineItem.title}
                    </span>
                    <span className='timeline__item__data__value'>
                      {timelineItem.value
                        ? timelineItem.value +
                          (timelineItem.id === 'rgbw-remote'
                            ? rgbwRemoteMulitpleLabel
                            : '')
                        : 'Choose an option'}
                    </span>
                  </div>
                </li>
              )
          )}
        </ul>
      </div>
    </>
  );
};

export default Timeline;
