import React from 'react';
import logo from '../../graphics/JCC-Leviton-logo-600px.png';

const AppTitle = () => {
  return (
    <div className='app-title'>
      <img className='logo' src={logo} alt='JCC Leviton' />
      <h1>LED Strip Configurator</h1>
    </div>
  );
};

export default AppTitle;
