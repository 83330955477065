import React, { useEffect, useGlobal } from 'reactn';
import LayoutContainerZones from '../../common/layoutContainerZones';
import SideBar from '../../common/sideBar';
import PageContentWrapper from '../../common/pageContentWrapper';
import DesignPageContentZones from './designPageContentZones';
import AppFooter from '../../common/appFooter';
import MobileHeader from '../../common/mobileHeader';
import MobileDrawer from '../../common/mobileDrawer';
import MobileMenu from '../../common/mobileMenu';
import ScrollToElement from '../../common/scrollToElement';

const DesignPage = (props) => {
  const { mobile, tablet, desktop, reactGA } = props;
  const [pageAttributes] = useGlobal('pageAttributes');
  const [zones] = useGlobal('zones');
  const [global] = useGlobal();

  reactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    if (zones.length === 0) {
      window.location.href = '/';
      return;
    }
    sessionStorage.setItem('jcc-global-state', JSON.stringify(global));
    ScrollToElement(pageAttributes.designPage.currentFieldID, mobile, 200);
    // eslint-disable-next-line
  }, [pageAttributes.designPage.currentFieldID, mobile]);

  const sidebarContent = (
    <>
      {mobile && (
        <div className='mobile-drawer__instruction'>
          <p className='lead'>Your layouts</p>
        </div>
      )}
      {desktop && (
        <div className='sidebar__instruction'>
          <p className='lead'>Build your LED&nbsp;Strip layout designs</p>
          <p>
            Add component pieces to design your layout. You may create multiple
            layouts as&nbsp;needed.
          </p>
        </div>
      )}
      {zones.map((zone, zoneIndex) => (
        <div className='zone-run-container'>
          {/* {withRemote && (
            <div className='zone-run-container-title'>
              <h4 style={{ margin: '20px', fontSize: '18px' }}>
                {`Zone ` + (zoneIndex + 1)}
              </h4>
            </div>
          )} */}
          {zone.layouts.map((layout, layoutIndex, array) => {
            let newFields = layout.fields.slice();

            if (layout.complete && !(newFields > layout.fields.length + 1)) {
              newFields.push({
                id:
                  'zone-' +
                  (zoneIndex + 1) +
                  '-layout-' +
                  layout.layoutNumber +
                  '-quantity',
                title: 'Quantity',
                value: layout.quantity,
                icon: '+',
                current: false,
              });
            }
            return (
              <LayoutContainerZones
                key={'zone-' + zoneIndex + '-layout-' + layoutIndex}
                zoneIndex={zoneIndex}
                layoutIndex={layoutIndex}
                fields={newFields}
                mobile={mobile}
              />
            );
          })}
        </div>
      ))}

      {/* {zones[pageAttributes.designPage.currentZoneIndex].layouts.map(
        (layout, layoutIndex, array) => {
          let newFields = layout.fields.slice();

          if (layout.complete && !(newFields > layout.fields.length + 1)) {
            newFields.push({
              id:
                'zone-' +
                (pageAttributes.designPage.currentZoneIndex + 1) +
                '-layout-' +
                layout.layoutNumber +
                '-quantity',
              title: 'Quantity',
              value: layout.quantity,
              icon: '+',
              current: false,
            });
          }
          return (
            <div class='zone-run-container'>
              <LayoutContainerZones
                key={
                  'zone-' +
                  (pageAttributes.designPage.currentZoneIndex + 1) +
                  '-layout-' +
                  layout.layoutNumber
                }
                zoneIndex={pageAttributes.designPage.currentZoneIndex}
                layoutIndex={layoutIndex}
                fields={newFields}
                mobile={mobile}
              />
            </div>
          );
        }
      )} */}
    </>
  );

  return (
    <>
      <div className='page'>
        {mobile && (
          <>
            <MobileHeader title='Design' />
          </>
        )}
        {desktop && <SideBar title='Design' content={sidebarContent} />}
        <PageContentWrapper
          content={
            <DesignPageContentZones
              mobile={mobile}
              tablet={tablet}
              desktop={desktop}
              pageAttributes={pageAttributes}
            />
          }
        />
        {mobile && (
          <MobileDrawer title='Design Summary' content={sidebarContent} />
        )}
        {desktop && <AppFooter desktop={true} />}
      </div>
      {mobile && <MobileMenu />}
    </>
  );
};

export default DesignPage;
