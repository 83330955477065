import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../graphics/JCC-Leviton-logo-600px.png';

const SplashPage = () => {
  return (
    <>
      <div className='splash'>
        <div className='splash__detail'>
          <div className='splash__detail__content'>
            <img className='logo' src={logo} alt='JCC Leviton logo' />
            <h1>LED Strip <br></br> Configurator</h1>
            <p className='lead ie-hide'>
              Design custom LED Strip kits using our simple, easy to use configuration&nbsp;tool.
            </p>
            <p className='ie-hide'>
              Specify your LED Strip requirements, then design your layout. We'll
              calculate the most economical driver available for you, and generate
              a kit design which you can order at your preferred
              electrical&nbsp;wholesaler.
            </p>
            <div className='button-group button-group--centered'>
              <Link className='button button--primary ie-hide' to='setup'>
                Get Started
              </Link>
            </div>
            {/* <p className='lead ie-show'>You are using an unsupported browser.</p>
            <p className='lead ie-show'>
              Please{' '}
              <a href='https://browsehappy.com/?locale=en'>
                upgrade your browser
              </a>{' '}
              to use the configurator.
            </p> */}
          </div>
          <div className='splash__detail__footer'>
            <small>
              <a href='https://www.jcc.co.uk'>View the JCC website</a>
            </small>
          </div>
        </div>
        <div className='splash__graphic'></div>
      </div>
    </>
  );
};

export default SplashPage;
