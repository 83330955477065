import React, { useState, useLayoutEffect, useEffect } from 'react';
import classNames from 'classnames';
import useKeyPress from '../../../hooks/useKeyPress';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';

// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// https://github.com/willmcpo/body-scroll-lock
// Only on mobile

// Set scrollable target element
// const targetElement = document.getElementByID('input-overlay');

// Disable body scoll when input-overlay is shown
// disableBodyScroll(targetElement);

// Disable body scoll when input-overlay is hidden
// enableBodyScroll(targetElement);

// Possibly needed, not sure!
// Useful if we have called disableBodyScroll for multiple target elements,
// and we just want a kill-switch to undo all that.
// OR useful for if the `hideTargetElement()` function got circumvented eg. visitor
// clicks a link which takes him/her to a different page within the app.
// clearAllBodyScrollLocks();

const DesignCardInput = props => {
  const { fieldItem, enterPressed } = props;
  const [cardValue, setCardValue] = useState(fieldItem.length);
  const [inputValueExists, setInputValueExists] = useState(
    fieldItem.length > 0 ? true : false
  );
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [checkEnter, setCheckEnter] = useState(true);

  let targetElement = document.querySelector('#input-overlay');

  useLayoutEffect(() => {
    // eslint-disable-next-line
    targetElement = document.querySelector('#input-overlay');
  }, []);

  useEffect(() => {
    disableBodyScroll(targetElement);

    return () => {
      enableBodyScroll(targetElement);
      clearAllBodyScrollLocks();
    };
    // eslint-disable-next-line
  }, []);

  function handleValueChange(e) {
    setShowError(false);
    setCheckEnter(true);
    if (e.target.value.length > 0 && !inputValueExists) {
      setInputValueExists(true);
    }

    if (e.target.value.length === 0 && inputValueExists) {
      setInputValueExists(false);
    }

    setCardValue(parseInt(e.target.value));
  }

  function handleEnterClick() {
    if (cardValue % fieldItem.validationdivisor > 0) {
      setShowError(true);
      setErrorText(
        'Value must be in ' + fieldItem.validationdivisor + 'mm increments'
      );
      return;
    }

    if (cardValue > fieldItem.validationlimit) {
      setShowError(true);

      let errorText =
        'Max. LED Strip length exceeded – enter a shorter length or remove this piece';

      if (fieldItem.title === 'DC Cable') {
        errorText =
          'Length must not exceed ' + fieldItem.validationlimit + 'mm';
      }
      setErrorText(errorText);
      return;
    }

    if (cardValue > 0) {
      return enterPressed(cardValue);
    }
    return;
  }

  // Handle Enter key press
  const enterPress = useKeyPress('Enter');
  if (checkEnter && enterPress && cardValue > 0) {
    handleEnterClick();
    setCheckEnter(false);
  }

  const inputOverlayClass = classNames({
    'input-overlay': true,
    active: true,
    'input-value-exists': inputValueExists,
    'input-failure': showError
  });

  return (
    <div className={inputOverlayClass} id='input-overlay'>
      <p className='input-overlay__label'>
        {`Enter length to the nearest ` + fieldItem.validationdivisor + `mm`}
      </p>
      <div className='input-overlay__field'>
        <input
          type='text'
          pattern='\d*' // this shows numeric keyboard on mobile https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input
          noValidate // see https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input#answer-15480933
          value={cardValue > 0 ? cardValue : ''}
          className='input-overlay__input'
          onChange={input => handleValueChange(input)}
          onBlur={() => {}}
          autoFocus
        />
      </div>
      <div className='input-overlay__actions'>
        <button
          className='input-overlay__submit button button--bordered'
          onClick={() => handleEnterClick()}
        >
          Enter
        </button>
        {showError && (
          <p
            className='input-overlay__message input-overlay__message--failure'
            data-part-input-message=''
          >
            {errorText}
          </p>
        )}
      </div>
    </div>
  );
};

export default DesignCardInput;
