import React, { Fragment, useGlobal } from 'reactn';
import DesignQuantityFieldZones from './designQuantityFieldZones';
import DesignFieldZones from './designFieldZones';
import Carousel from './designCarousel';

const DesignPageContentZones = (props) => {
  const { mobile, tablet, desktop, pageAttributes } = props;

  const [zones] = useGlobal('zones');

  return (
    <>
      {zones.map((zone, zoneIndex) =>
        zone.layouts.map((layout, layoutIndex, runarray) => (
          <Fragment key={'section-' + layoutIndex}>
            <div
              className='page-content__section'
              style={{ display: !layout.current ? 'none' : '' }}
            >
              <div
                className='field-set'
                key={'zone-' + zoneIndex + '-layout-' + layoutIndex}
                id={
                  `zone-` + (zoneIndex + 1) + `-layout-` + layout.layoutNumber
                }
              >
                {layout.fields.map((field, fieldIndex, array) => (
                  <DesignFieldZones
                    zoneIndex={zoneIndex}
                    layoutIndex={layoutIndex}
                    layoutNumber={layout.layoutNumber}
                    lengthRemaining={layout.lengthRemaining}
                    fieldIndex={fieldIndex}
                    index={fieldIndex + 1}
                    key={field.id}
                    mobile={mobile}
                    tablet={tablet}
                    desktop={desktop}
                    showFieldStatus={true}
                    content={
                      <Carousel
                        zoneIndex={zoneIndex}
                        layoutIndex={layoutIndex}
                        field={field}
                        fields={layout.fields}
                        fieldIndex={fieldIndex}
                        mobile={mobile}
                        tablet={tablet}
                        desktop={desktop}
                        currentPage='design'
                        pageAttributes={pageAttributes}
                      />
                    }
                  />
                ))}
                {layout.complete && (
                  <DesignQuantityFieldZones
                    zoneIndex={zoneIndex}
                    layoutIndex={layoutIndex}
                    mobile={mobile}
                    desktop={desktop}
                  />
                )}
              </div>
            </div>
          </Fragment>
        ))
      )}
    </>
  );
};

export default DesignPageContentZones;
