import React, { useGlobal } from 'reactn';
import ScrollToElement from './scrollToElement';
import LayoutTimelineZones from './layoutTimelineZones';

const LayoutContainerZones = (props) => {
  const { zoneIndex, layoutIndex, fields, mobile } = props;

  const [pageAttributes] = useGlobal('pageAttributes');
  const [zones, setZones] = useGlobal('zones');
  const [setupFieldChoices] = useGlobal('setupFieldChoices');
  const withRemote = setupFieldChoices.remoteQuantity > 0;

  let activeField = '';

  function handleLayoutContainerZonesClick() {
    let newZones = zones.slice();

    newZones.map((thisZone, thisZoneIndex) => {
      thisZone.layouts.map((thisLayout, thisLayoutIndex) => {
        if (thisZoneIndex === zoneIndex && thisLayoutIndex === layoutIndex) {
          thisLayout.current = true;
          let numFieldsInThisLayout = thisLayout.fields.length;
          for (let i = 0; i < numFieldsInThisLayout; i++) {
            if (i === numFieldsInThisLayout - 1) {
              if (thisLayout.complete) {
                activeField =
                  'zone-' +
                  (thisZoneIndex + 1) +
                  '-layout-' +
                  (thisLayoutIndex + 1) +
                  '-quantity';
              } else {
                thisLayout.fields[i].current = true;
                activeField = thisLayout.fields[i].id;
              }
            } else {
              thisLayout.fields[i].current = false;
            }
          }
        } else {
          thisLayout.current = false;
        }

        return null;
      });
      if (thisZoneIndex === zoneIndex) {
        thisZone.current = true;
      } else {
        thisZone.current = false;
      }

      return null;
    });

    setZones(newZones);
    ScrollToElement(activeField, mobile, 200);
    return null;
  }

  return (
    <div
      className={
        zones[zoneIndex].layouts[layoutIndex].current
          ? 'run-container'
          : 'run-container closed'
      }
    >
      {zones[zoneIndex].layouts[layoutIndex].current ? (
        <div className='run-container__run-number'>
          {withRemote
            ? `Zone ` +
              (zoneIndex + 1) +
              `: Layout ` +
              zones[zoneIndex].layouts[layoutIndex].layoutNumber
            : `Layout ` + zones[zoneIndex].layouts[layoutIndex].layoutNumber}
          <span className='run-container__run-qty'>
            {zones[zoneIndex].layouts[layoutIndex].quantity
              ? zones[zoneIndex].layouts[layoutIndex].quantity
              : '1'}
          </span>
        </div>
      ) : pageAttributes.designPage.allLayoutsEnded ? (
        <button
          className='run-container__run-number'
          onClick={() => {
            handleLayoutContainerZonesClick();
          }}
        >
          {withRemote
            ? `Zone ` +
              (zoneIndex + 1) +
              `: Layout ` +
              zones[zoneIndex].layouts[layoutIndex].layoutNumber
            : `Layout ` + zones[zoneIndex].layouts[layoutIndex].layoutNumber}
          <span className='run-container__run-qty'>
            {zones[zoneIndex].layouts[layoutIndex].quantity
              ? zones[zoneIndex].layouts[layoutIndex].quantity
              : '1'}
          </span>
        </button>
      ) : (
        <div className='run-container__run-number'>
          {withRemote
            ? `Zone ` +
              (zoneIndex + 1) +
              `: Layout ` +
              zones[zoneIndex].layouts[layoutIndex].layoutNumber
            : `Layout ` + zones[zoneIndex].layouts[layoutIndex].layoutNumber}
          <span className='run-container__run-qty'>
            {zones[zoneIndex].layouts[layoutIndex].quantity
              ? zones[zoneIndex].layouts[layoutIndex].quantity
              : '1'}
          </span>
        </div>
      )}

      {zones[zoneIndex].layouts[layoutIndex].current && (
        <div className='run-container__run-content'>
          <LayoutTimelineZones
            zoneIndex={zoneIndex}
            layoutIndex={layoutIndex}
            timelineItems={fields}
            mobile={mobile}
          />
        </div>
      )}
    </div>
  );
};

export default LayoutContainerZones;
