import React from 'react';
import SetupFieldCard from './setupFieldCard';
import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const Carousel = props => {
  const {
    fields,
    fieldsUpdateFunction,
    fieldIndex,
    mobile,
    tablet,
    desktop,
    currentPage,
    pageAttributes
  } = props;

  return (
    <>
      {mobile && !tablet && fields[fieldIndex].options && (
        <CarouselProvider
          naturalSlideWidth={356.5} // .card width plus slide padding
          naturalSlideHeight={400} // .card height
          totalSlides={fields[fieldIndex].options.length}
          visibleSlides={1.4}
          touchEnabled={true}
          dragEnabled={true}
          lockOnWindowScroll={true}
        >
          <Slider className={'carousel__slider'}>
            {fields[fieldIndex].options.map((fieldItem, fieldItemIndex) => (
              <SetupFieldCard
                fields={fields}
                fieldsUpdateFunction={fieldsUpdateFunction}
                fieldIndex={fieldIndex}
                fieldItemIndex={fieldItemIndex}
                key={fieldItem.id + fieldItemIndex}
                mobile={true}
                currentPage={currentPage}
                pageAttributes={pageAttributes}
              />
            ))}
          </Slider>
          {fields[fieldIndex].options.length > 1 && (
            <div className='carousel__buttons'>
              <ButtonBack>&lt;</ButtonBack>
              <ButtonNext>&gt;</ButtonNext>
            </div>
          )}
        </CarouselProvider>
      )}
      {tablet && fields[fieldIndex].options && (
        <CarouselProvider
          naturalSlideWidth={392} // .card width plus slide padding
          naturalSlideHeight={452} // .card height
          totalSlides={fields[fieldIndex].options.length}
          visibleSlides={2.4}
          touchEnabled={true}
          dragEnabled={true}
          lockOnWindowScroll={true}
        >
          <Slider className={'carousel__slider'}>
            {fields[fieldIndex].options.map((fieldItem, fieldItemIndex) => (
              <SetupFieldCard
                fieldItem={fieldItem}
                fields={fields}
                fieldsUpdateFunction={fieldsUpdateFunction}
                fieldIndex={fieldIndex}
                fieldItemIndex={fieldItemIndex}
                key={fieldItem.id + fieldItemIndex}
                mobile={mobile}
                currentPage={currentPage}
                pageAttributes={pageAttributes}
              />
            ))}
          </Slider>
          {fields[fieldIndex].options.length > 2 && (
            <div className='carousel__buttons'>
              <ButtonBack>&lt;</ButtonBack>
              <ButtonNext>&gt;</ButtonNext>
            </div>
          )}
        </CarouselProvider>
      )}
      {desktop && !tablet && fields[fieldIndex].options && (
        <CarouselProvider
          naturalSlideWidth={392} // .card width plus slide padding
          naturalSlideHeight={452} // .card height
          totalSlides={fields[fieldIndex].options.length}
          visibleSlides={3}
          touchEnabled={true}
          dragEnabled={true}
          lockOnWindowScroll={true}
        >
          {fields[fieldIndex].options.length > 3 && (
            <div className='carousel__buttons'>
              <ButtonBack>&lt;</ButtonBack>
              <ButtonNext>&gt;</ButtonNext>
            </div>
          )}
          <Slider className={'carousel__slider'}>
            {fields[fieldIndex].options.map((fieldItem, fieldItemIndex) => (
              <SetupFieldCard
                fields={fields}
                fieldsUpdateFunction={fieldsUpdateFunction}
                fieldIndex={fieldIndex}
                fieldItemIndex={fieldItemIndex}
                key={fieldItem.id + fieldItemIndex}
                mobile={false}
                currentPage={currentPage}
                pageAttributes={pageAttributes}
              />
            ))}
          </Slider>
        </CarouselProvider>
      )}
    </>
  );
};

export default Carousel;
