import React, { useGlobal, useState } from 'reactn';
import ReactModal from 'react-modal';
import { Slide } from 'pure-react-carousel';
import classNames from 'classnames';
import ScrollToElement from '../../common/scrollToElement';
import SetupCardInput from './setupCardInput';
import useKeyPress from '../../../hooks/useKeyPress';

const SetupFieldCard = (props) => {
  const { fieldIndex, fieldItemIndex, mobile } = props;

  const [showDialogBox, setShowDialogBox] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');

  const [zones] = useGlobal('zones');
  const [setupFields, setSetupFields] = useGlobal('setupFields');
  const [setupFieldChoices, setSetupFieldChoices] = useGlobal(
    'setupFieldChoices'
  );
  const [pageAttributes, setPageAttributes] = useGlobal('pageAttributes');

  ReactModal.setAppElement('body');

  function handleSwitchLEDTypeRGBWButtonPress() {
    setShowDialogBox(false);
    pageAttributes.setupPage.swapSetupFields = true;
    pageAttributes.setupPage.setupFieldSet = 'led-type-rgbw';
    setPageAttributes(pageAttributes);
    return;
  }

  function handleSwitchLEDTypeWhiteButtonPress() {
    setShowDialogBox(false);
    pageAttributes.setupPage.swapSetupFields = true;
    pageAttributes.setupPage.setupFieldSet = 'led-type-white';
    setPageAttributes(pageAttributes);
    return;
  }

  function handleSwitchToSingleLengthButtonPress() {
    setShowDialogBox(false);
    let newfieldset = JSON.parse(JSON.stringify(setupFields));
    newfieldset[fieldIndex].value = 'Single Length';
    newfieldset[fieldIndex].length = 0;
    newfieldset[fieldIndex].options[0].selected = false;
    newfieldset[fieldIndex].options[0].deselected = true;
    newfieldset[fieldIndex].options[fieldItemIndex].deselected = false;
    newfieldset[fieldIndex].options[fieldItemIndex].showinput = true;
    pageAttributes.setupPage.singleLength = true;
    setSetupFields(newfieldset);
  }

  function handleCancelButtonPress() {
    setShowDialogBox(false);
  }

  function presentSwitchLEDTypeRGBWPrompt() {
    setModalTitle('Are you sure?');
    setModalBody(
      <>
        <p>
          Switching to an LED type of RGBW will clear your setup choices{' '}
          {zones[0].layouts[0].length > 0 ? ' and your current design.' : '.'}{' '}
          This cannot be undone.
        </p>
        <p className='large-emphasis' />{' '}
        <div className='button-group button-group--vertical'>
          <button
            onClick={() => {
              handleSwitchLEDTypeRGBWButtonPress();
            }}
            className='button button--primary'
          >
            Yes, change LED type to RGBW
          </button>
          <button
            onClick={() => {
              handleCancelButtonPress();
            }}
            className='button'
          >
            Cancel
          </button>
        </div>
      </>
    );
    setShowDialogBox(true);
  }

  function presentSwitchLEDTypeWhitePrompt() {
    setModalTitle('Are you sure?');
    setModalBody(
      <>
        <p>
          Switching to an LED type of White will clear your setup choices{' '}
          {zones[0].layouts[0].length > 0 ? ' and your current design.' : '.'}{' '}
          This cannot be undone.
        </p>
        <p className='large-emphasis' />{' '}
        <div className='button-group button-group--vertical'>
          <button
            onClick={() => {
              handleSwitchLEDTypeWhiteButtonPress();
            }}
            className='button button--primary'
          >
            Yes, change LED type to White
          </button>
          <button
            onClick={() => {
              handleCancelButtonPress();
            }}
            className='button'
          >
            Cancel
          </button>
        </div>
      </>
    );
    setShowDialogBox(true);
  }

  function presentSwitchToSingleLengthPrompt() {
    setModalTitle('Are you sure?');
    setModalBody(
      <>
        <p>
          Switching to a single length design will clear your current design and
          allow you to enter a length for a single piece of LED Strip. This
          cannot be undone.
        </p>
        <p className='large-emphasis' />{' '}
        <div className='button-group button-group--vertical'>
          <button
            onClick={() => {
              handleSwitchToSingleLengthButtonPress();
            }}
            className='button button--primary'
          >
            Yes, change to single length design
          </button>
          <button
            onClick={() => {
              handleCancelButtonPress();
            }}
            className='button'
          >
            Cancel
          </button>
        </div>
      </>
    );
    setShowDialogBox(true);
  }

  function handleInputItemEntrySuccess(newValue) {
    let newfieldset = setupFields.slice();
    const newVal = newValue;

    newfieldset[fieldIndex].length = newVal;
    newfieldset[fieldIndex].options[fieldItemIndex].length = newVal;
    newfieldset[fieldIndex].options[fieldItemIndex].selected = true;
    newfieldset[fieldIndex].options[fieldItemIndex].disabled = false;
    newfieldset[fieldIndex].options[fieldItemIndex].showinput = false;
    document.body.classList.remove('input-overlay-active');

    if (
      newfieldset[fieldIndex].options[fieldItemIndex].id ===
      'rgbw-remote-handheld'
    ) {
      if (newfieldset[fieldIndex].length === 2) {
        newfieldset[fieldIndex].options[fieldItemIndex].imagepath =
          'JC121449-hand-held-remote-double.jpg';
      } else {
        newfieldset[fieldIndex].options[fieldItemIndex].imagepath =
          'JC121449-hand-held-remote-single.jpg';
      }
      newfieldset[fieldIndex].options[fieldItemIndex + 1].imagepath =
        'JC121373-wall-mounted-control-single.jpg';
      newfieldset[fieldIndex].options[fieldItemIndex + 2].imagepath =
        'no-remote-or-controller-required-icon-grey.jpg';
    }
    if (
      newfieldset[fieldIndex].options[fieldItemIndex].id ===
      'rgbw-remote-wall-mounted'
    ) {
      if (newfieldset[fieldIndex].length === 2) {
        newfieldset[fieldIndex].options[fieldItemIndex].imagepath =
          'JC121373-wall-mounted-control-double.jpg';
      } else {
        newfieldset[fieldIndex].options[fieldItemIndex].imagepath =
          'JC121373-wall-mounted-control-single.jpg';
      }
      newfieldset[fieldIndex].options[fieldItemIndex - 1].imagepath =
        'JC121449-hand-held-remote-single.jpg';
      newfieldset[fieldIndex].options[fieldItemIndex + 1].imagepath =
        'no-remote-or-controller-required-icon-grey.jpg';
    }

    // New below

    const numSetupFields = setupFields.length;

    if (parseInt(fieldIndex) + 1 < numSetupFields) {
      const fieldID = newfieldset[fieldIndex + 1].id;

      if (newfieldset[fieldIndex + 1].disabled) {
        newfieldset[fieldIndex + 1].disabled = false;
        pageAttributes.setupPage.postSwapNavigationTarget =
          newfieldset[fieldIndex + 1].id;
      }

      ScrollToElement(fieldID, mobile);
    }

    // New above

    setSetupFields(newfieldset);

    if (!pageAttributes.setupPage.singleLength === true) {
      pageAttributes.setupPage.singleLength = true;
    }
    if (!pageAttributes.setupPage.createLayout === true) {
      pageAttributes.setupPage.createLayout = true;
    }

    setPageAttributes(pageAttributes);
  }

  // Handle Escape key press
  const escPress = useKeyPress('Escape');
  if (escPress && setupFields[fieldIndex].options[fieldItemIndex].showinput) {
    if (setupFields[fieldIndex].options[fieldItemIndex].type === 'input') {
      setupFields[fieldIndex].options[fieldItemIndex].showinput = false;
      document.body.classList.remove('input-overlay-active');

      if (setupFields[fieldIndex].options[fieldItemIndex].length === 0) {
        setupFields[fieldIndex].options[fieldItemIndex].selected = false;
        setupFields[fieldIndex].options[fieldItemIndex].deselected = true;
      }
    }
  }

  // Handle input overlay button press
  // This will need updating as I've copied this from the ESC key function above, but it only removes the body class, not the overlay elements themselves
  // function handleInputOverlayClose() {
  //   if (setupFields[fieldIndex].options[fieldItemIndex].showinput) {
  //     if (setupFields[fieldIndex].options[fieldItemIndex].type === 'input') {
  //       setupFields[fieldIndex].options[fieldItemIndex].showinput = false;
  //       document.body.classList.remove('input-overlay-active');

  //       if (setupFields[fieldIndex].options[fieldItemIndex].length === 0) {
  //         setupFields[fieldIndex].options[fieldItemIndex].selected = false;
  //         setupFields[fieldIndex].options[fieldItemIndex].deselected = true;
  //       }
  //     }
  //   }
  // }

  function displayItemContent(fieldItem) {
    if (
      fieldItem.type === 'input' &&
      fieldItem.showinput &&
      setupFields[fieldIndex].current &&
      mobile
    ) {
      document.body.classList.add('input-overlay-active');
      return (
        <>
          <SetupCardInput
            fieldItem={fieldItem}
            enterPressed={handleInputItemEntrySuccess}
          />
          {/* <button 
            className='input-overlay-close'
            onClick={() => handleInputOverlayClose()}
          /> */}
        </>
      );
    }

    if (
      fieldItem.type === 'input' &&
      fieldItem.showinput &&
      setupFields[fieldIndex].current &&
      !mobile
    ) {
      return (
        <>
          <SetupCardInput
            fieldItem={fieldItem}
            enterPressed={handleInputItemEntrySuccess}
          />
          <img
            className=''
            src={require('../../../graphics/' + fieldItem.imagepath)}
            alt={fieldItem.title}
          />
        </>
      );
    } else {
      return (
        <img
          className=''
          src={require('../../../graphics/' + fieldItem.imagepath)}
          alt={fieldItem.title}
        />
      );
    }
  }

  function handleItemClicked(item) {
    if (setupFields[fieldIndex].current) {
      if (setupFields.length > 2) {

        if (
          setupFields[fieldIndex].options[fieldItemIndex].id ===
            'led-type-white' &&
          !setupFields[fieldIndex].options[fieldItemIndex].selected &&
          setupFields[1].value !== ''
        ) {
          presentSwitchLEDTypeWhitePrompt();
          return;
        }

        if (
          setupFields[fieldIndex].options[fieldItemIndex].id ===
            'led-type-rgbw' &&
          !setupFields[fieldIndex].options[fieldItemIndex].selected &&
          setupFields[1].value !== ''
        ) {
          presentSwitchLEDTypeRGBWPrompt();
          return;
        }
      }

      if (
        setupFields[fieldIndex].options[fieldItemIndex].id ===
          'layout-type-single-length' &&
        setupFields[fieldIndex].value === 'Custom Design' &&
        zones[0].layouts[0].length > 0
      ) {
        presentSwitchToSingleLengthPrompt();
        return;
      }

      if (
        (setupFields[fieldIndex].options[fieldItemIndex].id ===
          'layout-type-custom' ||
          setupFields[fieldIndex].options[fieldItemIndex].id ===
            'layout-type-single-length') &&
        setupFieldChoices.ledType === 'White'
      ) {
        let newfieldset = setupFields.slice();

        if (setupFieldChoices.driverRequired === 'JCC Driver') {
          if (setupFieldChoices.wattage === 4.8) {
            if (setupFieldChoices.dimming === 'Non-dimmable') {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 37500;
            }
            if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 15600;
            }
          }

          if (setupFieldChoices.wattage === 9.6) {
            if (setupFieldChoices.dimming === 'Non-dimmable') {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 18750;
            }
            if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 7800;
            }
          }
          if (setupFieldChoices.wattage === 14.4) {
            if (setupFieldChoices.dimming === 'Non-dimmable') {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 12500;
            }
            if (setupFieldChoices.dimming === 'Dimmable (Triac)') {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 5200;
            }
          }
        } else {
          newfieldset[fieldIndex].options[
            fieldItemIndex
          ].validationlimit = 50000;
        }

        setupFieldChoices.validationlimit =
          newfieldset[fieldIndex].options[fieldItemIndex].validationlimit;

        setSetupFieldChoices(setupFieldChoices);
        setSetupFields(newfieldset);
      }

      if (
        (setupFields[fieldIndex].options[fieldItemIndex].id ===
          'layout-type-custom' ||
          setupFields[fieldIndex].options[fieldItemIndex].id ===
            'layout-type-single-length') &&
        setupFieldChoices.ledType === 'RGBW'
      ) {
        let newfieldset = setupFields.slice();

        if (setupFieldChoices.wattage === 7.2) {
          if (setupFieldChoices.driverRequired === 'JCC Driver') {
            newfieldset[fieldIndex].options[
              fieldItemIndex
            ].validationlimit = 25000;
          }

          if (setupFieldChoices.driverRequired === 'Other Driver') {
            if (
              setupFieldChoices.remoteType === 'Handheld Remote' ||
              setupFieldChoices.remoteType === 'Wall Mounted Remote'
            ) {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 48000;
            } else {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 50000;
            }
          }
        }
        if (setupFieldChoices.wattage === 17.6) {
          if (setupFieldChoices.driverRequired === 'JCC Driver') {
            newfieldset[fieldIndex].options[
              fieldItemIndex
            ].validationlimit = 10000;
          }

          if (setupFieldChoices.driverRequired === 'Other Driver') {
            if (
              setupFieldChoices.remoteType === 'Handheld Remote' ||
              setupFieldChoices.remoteType === 'Wall Mounted Remote'
            ) {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 19800;
            } else {
              newfieldset[fieldIndex].options[
                fieldItemIndex
              ].validationlimit = 50000;
            }
          }
        }

        setupFieldChoices.validationlimit =
          newfieldset[fieldIndex].options[fieldItemIndex].validationlimit;

        setSetupFieldChoices(setupFieldChoices);
        setSetupFields(newfieldset);
      }

      const numFields = setupFields.length;
      let newfieldset = setupFields.slice();
      const numOptions = newfieldset[fieldIndex].options.length;

      if (newfieldset[fieldIndex].options[fieldItemIndex].type === 'input') {
        if (!newfieldset[fieldIndex].options[fieldItemIndex].showinput) {
          newfieldset[fieldIndex].options[fieldItemIndex].showinput = true;
          newfieldset[fieldIndex].options[fieldItemIndex].selected = true;
          newfieldset[fieldIndex].options[fieldItemIndex].deselected = false;
          newfieldset[fieldIndex].value = item.title;

          for (let i = 0; i < numOptions; i++) {
            if (i !== fieldItemIndex) {
              newfieldset[fieldIndex].options[i].selected = false;
              newfieldset[fieldIndex].options[i].deselected = true;

              if (newfieldset[fieldIndex].options[i].type === 'input') {
                newfieldset[fieldIndex].options[i].showinput = false;
              }
            }
          }

          setSetupFields(newfieldset);
          return;
        }
      } else {
        if (
          newfieldset[fieldIndex].id === 'layout-type' &&
          item.title === 'Custom Design'
        ) {
          pageAttributes.setupPage.createLayout = true;
          pageAttributes.setupPage.singleLength = false;
        }

        setPageAttributes(pageAttributes);

        newfieldset[fieldIndex].options[fieldItemIndex].showinput = false;
        newfieldset[fieldIndex].length = 0;

        for (let i = 0; i < numOptions; i++) {
          if (i !== fieldItemIndex) {
            newfieldset[fieldIndex].options[i].selected = false;
            newfieldset[fieldIndex].options[i].deselected = true;

            if (newfieldset[fieldIndex].options[i].type === 'input') {
              newfieldset[fieldIndex].options[i].showinput = false;
              newfieldset[fieldIndex].options[i].length = 0;
            }
          }
        }
      }

      if (newfieldset[fieldIndex].id === 'led-type') {
        if (item.id !== pageAttributes.setupPage.setupFieldSet) {
          pageAttributes.setupPage.swapSetupFields = true;
          pageAttributes.setupPage.setupFieldSet = item.id;
          setPageAttributes(pageAttributes);
          return;
        }
      }

      if (
        newfieldset[fieldIndex].id === 'wattage-white' ||
        newfieldset[fieldIndex].id === 'wattage-rgbw'
      ) {
        newfieldset[fieldIndex].wattage =
          newfieldset[fieldIndex].options[fieldItemIndex].wattage;
      }

      if (newfieldset[fieldIndex].id === 'driver-required') {
        if (
          newfieldset[fieldIndex].options[fieldItemIndex].id ===
          'driver-required-no'
        ) {
          newfieldset[fieldIndex].options[fieldItemIndex].imagepath =
            'other-driver-blue.jpg';
        } else {
          newfieldset[fieldIndex].options[fieldItemIndex + 1].imagepath =
            'other-driver-grey.jpg';
        }

        // Insert check and validation settings here...
        // let newSetupFieldChoices = JSON.parse(
        //   JSON.stringify(setupFieldChoices)
        // );

        // if (newSetupFieldChoices.ledType === 'White') {
        //   if (
        //     newfieldset[fieldIndex].options[fieldItemIndex].id ===
        //     'driver-required-yes'
        //   ) {
        //     if (newSetupFieldChoices.wattage === 4.8) {
        //       if (newSetupFieldChoices.dimming === 'Non-dimmable') {
        //         newSetupFieldChoices.validationlimit = 3750;
        //       }
        //       if (newSetupFieldChoices.dimming === 'Dimmable (Triac)') {
        //         newSetupFieldChoices.validationlimit = 1560;
        //       }
        //       if (newSetupFieldChoices.dimming === 'Dimmable (1–10V)') {
        //         newSetupFieldChoices.validationlimit = 3125;
        //       }
        //     }
        //     if (newSetupFieldChoices.wattage === 9.6) {
        //       if (newSetupFieldChoices.dimming === 'Non-dimmable') {
        //         newSetupFieldChoices.validationlimit = 1875;
        //       }
        //       if (newSetupFieldChoices.dimming === 'Dimmable (Triac)') {
        //         newSetupFieldChoices.validationlimit = 780;
        //       }
        //       if (newSetupFieldChoices.dimming === 'Dimmable (1–10V)') {
        //         newSetupFieldChoices.validationlimit = 1560;
        //       }
        //     }
        //     if (newSetupFieldChoices.wattage === 14.4) {
        //       if (newSetupFieldChoices.dimming === 'Non-dimmable') {
        //         newSetupFieldChoices.validationlimit = 1250;
        //       }
        //       if (newSetupFieldChoices.dimming === 'Dimmable (Triac)') {
        //         newSetupFieldChoices.validationlimit = 520;
        //       }
        //       if (newSetupFieldChoices.dimming === 'Dimmable (1–10V)') {
        //         newSetupFieldChoices.validationlimit = 1040;
        //       }
        //     }
        //   } else {
        //     newSetupFieldChoices.validationlimit = 5000;
        //   }
        // } else {
        //   if (newSetupFieldChoices.wattage === 7.2) {
        //     if (
        //       newfieldset[fieldIndex].options[fieldItemIndex].id ===
        //       'driver-required-yes'
        //     ) {
        //       newSetupFieldChoices.validationlimit = 2500;
        //     }

        //     if (
        //       newfieldset[fieldIndex].options[fieldItemIndex].id ===
        //       'driver-required-no'
        //     ) {
        //       if (
        //         newSetupFieldChoices.remoteType === 'Handheld Remote' ||
        //         newSetupFieldChoices.remoteType === 'Wall Mounted Remote'
        //       ) {
        //         newSetupFieldChoices.validationlimit = 4800;
        //       } else {
        //         newSetupFieldChoices.validationlimit = 5000;
        //       }
        //     }
        //   }
        //   if (newSetupFieldChoices.wattage === 17.6) {
        //     if (
        //       newfieldset[fieldIndex].options[fieldItemIndex].id ===
        //       'driver-required-no'
        //     ) {
        //       newSetupFieldChoices.validationlimit = 1000;
        //     }

        //     if (
        //       newfieldset[fieldIndex].options[fieldItemIndex].id ===
        //       'driver-required-no'
        //     ) {
        //       if (
        //         newSetupFieldChoices.remoteType === 'Handheld Remote' ||
        //         newSetupFieldChoices.remoteType === 'Wall Mounted Remote'
        //       ) {
        //         newSetupFieldChoices.validationlimit = 1980;
        //       } else {
        //         newSetupFieldChoices.validationlimit = 5000;
        //       }
        //     }
        //   }
        // }
        // console.log(
        //   'validation limit: ' + newSetupFieldChoices.validationlimit
        // );

        // setSetupFieldChoices(newSetupFieldChoices);

        // let newZoneLayout = [];
        // newZoneLayout = CreateCustomZoneLayout(setupFieldChoices);
        // pageAttributes.setupPage.createLayout = false;
        // pageAttributes.designPage.status = 'incomplete';
        // pageAttributes.reviewPage.status = 'design required';
        // pageAttributes.sendPage.status = 'design required';
        // setZones(newZoneLayout);
      }

      if (
        setupFieldChoices.ledType === 'White' &&
        newfieldset[fieldIndex].id === 'driver-required' &&
        newfieldset[fieldIndex].options[fieldItemIndex].title === 'Other Driver'
      ) {
        pageAttributes.setupPage.removeDimming = true;
        setPageAttributes(pageAttributes);
      }
      if (
        setupFieldChoices.ledType === 'White' &&
        newfieldset[fieldIndex].id === 'driver-required' &&
        newfieldset[fieldIndex].options[fieldItemIndex].title === 'JCC Driver'
      ) {
        pageAttributes.setupPage.addDimming = true;
        setPageAttributes(pageAttributes);
      }

      if (newfieldset[fieldIndex].id === 'dimming') {
        // pageAttributes.setupPage.checkLimits = true;
        setPageAttributes(pageAttributes);
      }

      if (newfieldset[fieldIndex].id === 'environment') {
        newfieldset[fieldIndex].iprating =
          newfieldset[fieldIndex].options[fieldItemIndex].iprating;

        // Set Slimline profile as deslected if IP65 has been selected
        if(setupFieldChoices.ledType === 'White') {

// console.log(newfieldset[5]);
          if(newfieldset[fieldIndex].options[fieldItemIndex].iprating === 'IP65') {
            newfieldset[5].options[3].deselected = true;
          } else {
            newfieldset[5].options[3].deselected = false;
          }

        } else {

// console.log(newfieldset[6]);
          if(newfieldset[fieldIndex].options[fieldItemIndex].iprating === 'IP65') {
            newfieldset[6].options[3].deselected = true;
          } else {
            newfieldset[6].options[3].deselected = false;
          }
        }

      }

      // Don't allow slimline profile to be selected if IP65 has been selected
      if(newfieldset[fieldIndex].id === 'profile'){
        if(setupFieldChoices.environment === 'IP65' && newfieldset[fieldIndex].options[fieldItemIndex].id === 'profile-slimline-surface-mounted') {
          console.log('Can not select slimline surface mounted with IP65');
          return;
        }
      }   


      if (!item.selected) {
        newfieldset[fieldIndex].options[fieldItemIndex].selected = true;
        newfieldset[fieldIndex].options[fieldItemIndex].deselected = false;
        newfieldset[fieldIndex].value = item.title;

        for (let i = 0; i < numFields; i++) {
          if (!i === fieldIndex) {
            newfieldset[fieldIndex].current = false;
          }
        }

        for (let i = 0; i < numOptions; i++) {
          if (i !== fieldItemIndex) {
            newfieldset[fieldIndex].options[i].selected = false;
            newfieldset[fieldIndex].options[i].deselected = true;

            if (newfieldset[fieldIndex].options[i].type === 'input') {
              newfieldset[fieldIndex].options[i].showinput = false;
            }
          }
        }
      }

      if (
        newfieldset[fieldIndex].options[fieldItemIndex].id ===
        'rgbw-remote-none'
      ) {
        newfieldset[fieldIndex].options[fieldItemIndex].imagepath =
          'no-remote-or-controller-required-icon-blue.jpg';
        newfieldset[fieldIndex].options[fieldItemIndex - 1].imagepath =
          'JC121373-wall-mounted-control-single.jpg';
        newfieldset[fieldIndex].options[fieldItemIndex - 2].imagepath =
          'JC121449-hand-held-remote-single.jpg';
      }

      if (
        !(
          pageAttributes.setupPage.addDimming ||
          pageAttributes.setupPage.removeDimming
        )
      ) {
        if (parseInt(fieldIndex) + 1 < numFields) {
          const fieldID = newfieldset[fieldIndex + 1].id;

          if (newfieldset[fieldIndex + 1].disabled) {
            newfieldset[fieldIndex + 1].disabled = false;
            pageAttributes.setupPage.postSwapNavigationTarget =
              newfieldset[fieldIndex + 1].id;
          }

          ScrollToElement(fieldID, mobile);
        }
      }

      setSetupFields(newfieldset);
      // setPageAttributes(pageAttributes);
      if (!pageAttributes.setupPage.createLayout === true) {
        pageAttributes.setupPage.createLayout = true;
        setPageAttributes(pageAttributes);
      }
    }
  }

  const cardClass = classNames({
    card: true,
    selected: setupFields[fieldIndex].options[fieldItemIndex].selected,
    deselected: setupFields[fieldIndex].options[fieldItemIndex].deselected,
  });

  return (
    <>
      <ReactModal
        isOpen={showDialogBox}
        style={{
          overlay: {
            backgroundColor: '#000',
            opacity: '0.8',
            zIndex: '1000000000000',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            borderRadius: 'none',
            border: 'none',
            marginRight: '-50%',
            minWidth: '320px',
            padding: '0px',
            opacity: '1.0',
            transform: 'translate(-50%, -50%)',
            zIndex: '1000000000000000',
          },
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
      >
        <div className='ReactModal__Content__Title'>
          <h3>{modalTitle}</h3>
        </div>
        <div className='ReactModal__Content__Body'>{modalBody}</div>
      </ReactModal>
      <Slide
        key={setupFields[fieldIndex].options[fieldItemIndex].id}
        index={fieldItemIndex}
        onClick={
          setupFields[fieldIndex].options[fieldItemIndex].type === 'input' &&
          setupFields[fieldIndex].options[fieldItemIndex].showinput
            ? () => {}
            : () =>
                handleItemClicked(
                  setupFields[fieldIndex].options[fieldItemIndex]
                )
        }
      >
        <div className={cardClass}>
          <div className='card__top'>
            <div className='card__status'>
              {setupFields[fieldIndex].options[fieldItemIndex].selected && (
                <div className='card__status__icon card__status__icon--success' />
              )}
              {setupFields[fieldIndex].id === 'layout-type' &&
                setupFields[fieldIndex].options[fieldItemIndex].length > 0 &&
                !setupFields[fieldIndex].options[fieldItemIndex].deselected && (
                  <div className='card__status__value card__status__value--success'>
                    {setupFields[fieldIndex].options[fieldItemIndex].length}
                  </div>
                )}
              {setupFields[fieldIndex].id === 'rgbw-remote' &&
                setupFields[fieldIndex].options[fieldItemIndex].length > 0 &&
                !setupFields[fieldIndex].options[fieldItemIndex].deselected && (
                  <div className='card__status__quantity card__status__quantity--success'>
                    {setupFields[fieldIndex].options[fieldItemIndex].length}
                  </div>
                )}
            </div>
            {displayItemContent(
              setupFields[fieldIndex].options[fieldItemIndex]
            )}
          </div>
          <div className='card__text'>
            <p className='card__title'>
              {setupFields[fieldIndex].options[fieldItemIndex].title}
            </p>
            <p className='card__description'>
              {setupFields[fieldIndex].options[fieldItemIndex].description}
            </p>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default SetupFieldCard;
