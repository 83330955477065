import { setGlobal, resetGlobal } from 'reactn';

function InitiateGlobalState(reset, useLocal, localState, appVersion) {
  let initState = {};

  if (reset) {
    resetGlobal();
    sessionStorage.setItem('jcc-global-state', JSON.stringify({}));
  }

  if (useLocal) {
    initState = JSON.parse(localState);
  } else {
    initState = {
      appversion: appVersion,
      mobileDrawerOpen: false,
      mobileMenuOpen: false,
      // remoteOrderDB: 'https://ledstrip-couchdb.jcc.co.uk/test-v2-led-strip',
      // remoteOrderDB: 'https://ledstrip-couchdb.jcc.co.uk/jcc-led-strip-orders',
      designModalOpen: false,
      showMobileFieldInput: false,
      mobileFieldItem: {},
      mobileFieldItemFunction: {},
      pageAttributes: {
        setupPage: {
          status: 'incomplete',
          currentFieldID: '',
          previousFieldID: '',
          nextFieldID: '',
          swapSetupFields: true,
          setupFieldSet: 'unknown',
          navigateAfterSwap: false,
          postSwapNavigationTarget: '',
          checkLimits: false,
          singleLength: false,
          createLayout: false,
          modalOpen: false,
        },
        designPage: {
          status: 'setup required',
          allLayoutsEnded: false,
          currentZoneIndex: 0,
          currentLayoutIndex: 0,
          currentFieldID: '',
          previousFieldID: '',
          nextFieldID: '',
          modalOpen: false,
        },
        reviewPage: {
          status: 'setup required',
          currentFieldID: 'zone-1-layout-1',
          previousFieldID: '',
          nextFieldID: '',
          modalOpen: false,
        },
        sendPage: {
          status: 'setup required',
          currentFieldID: '',
          previousFieldID: '',
          nextFieldID: '',
          modalOpen: false,
        },
      },
      setupFields: [
        {
          id: 'led-type',
          current: true,
          disabled: false,
          title: 'Choose LED type',
          value: '',
          shorttitle: 'led type',
          instruction:
            'Choose from White LED (single colour), or RGBW LED. Available in a range of different variants to suit all applications.',
          fieldnote: '',
          options: [
            {
              id: 'led-type-white',
              selected: false,
              deselected: false,
              title: 'White',
              description: 'Single colour LED',
              imagepath: 'white-led.jpg',
            },
            {
              id: 'led-type-rgbw',
              selected: false,
              deselected: false,
              title: 'RGBW',
              description: 'Red, green, blue & white LED',
              imagepath: 'rgbw-led.jpg',
            },
          ],
        },
      ],
      setupFieldChoices: {
        ledType: '',
        remoteType: '',
        remoteQuantity: 0,
        colourTemp: '',
        wattage: 0,
        environment: '',
        driverRequired: '',
        dimming: '',
        profile: '',
        customLayout: true,
        validationlimit: 5000,
        stripLength: 0,
        ordernumber: '',
        orderlogged: 'No',
        orderemailed: 'No',
        name: '',
        email: '',
        phone: '',
        note: '',
        agreedGDPR: true,
        rgbwIP65warning:
          'Please be aware that the specified driver is IP20. If installing the LED Strip in wet areas ensure the driver is adequately and safely protected from moisture in a suitable enclosure or installed in a dry area. It is recommended that luminaires are installed by a qualified electrician and the installation complies with the local current wiring regulations.',
      },
      sendfields: [
        {
          id: 'your-name',
          title: 'Your name',
          value: '',
          description: '',
          placeholder: 'Enter your name here…',
          failuremessage: 'Name is a required field.',
          inputrows: 1,
        },
        {
          id: 'your-email-address',
          title: 'Your email address',
          value: '',
          description:
            'You will receive a copy of your LED Strip design by email.',
          placeholder: 'Enter your email here…',
          failuremessage: 'Email address is a required field.',
          inputrows: 1,
        },
        {
          id: 'your-phone-number',
          title: 'Your phone number (optional)',
          value: '',
          description:
            'Add your phone number if you would like your wholesaler to be able to contact you direct to discuss your design.',
          placeholder: 'Your phone number',
          failuremessage: 'Phone number is a required field.',
          inputrows: 1,
        },
        {
          id: 'your-notes',
          title: 'Would you like to add any notes to the email? (optional)',
          value: '',
          description:
            'Please use this field to provide instructions to the wholesaler regarding any unique elements of the installation.',
          placeholder: 'Add your notes here…',
          inputrows: 1,
        },
        {
          id: 'send-your-design-summary',
          title: 'Send your design summary',
          value: '',
          description:
            'You will receive a copy of your design summary by email. Please present the summary email to your wholesaler to request a quote or place an order.',
          agreed: false,
          failuremessage: 'Fail',
          showsendbutton: true,
        },
      ],
      tradeDiscount: 0,
      zones: [],
      zonesToSend: [],
      // layouts: [],
    };
  }

  setGlobal(initState);
}

export default InitiateGlobalState;
