import React, { useState, useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import AddPieceToLayoutZones from './addPieceToLayoutZones';
// import CreateCustomZoneLayout from './createCustomZoneLayout';

const FieldActionButtonsZones = (props) => {
  const {
    zoneIndex,
    layoutIndex,
    fieldIndex,
    showSetupCompleteStartCustomButton,
    showSetupCompleteContinueCustomButton,
    showSetupCompleteSingleButton,
    showAddNextPieceButton,
    showRemoveThisLayoutButton,
    showEndLayoutButton,
    showFinishDesignButton,
    showRemoveThisPieceButton,
    showAddAnotherLayoutButton,
    showAddAnotherZoneButton,
    showRemoveThisZoneButton,
    showEditLayoutButton,
  } = props;

  const [showDialogBox, setShowDialogBox] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [zones, setZones] = useGlobal('zones');
  const [pageAttributes] = useGlobal('pageAttributes');
  const [setupFieldChoices] = useGlobal('setupFieldChoices');

  const withRemote = setupFieldChoices.remoteQuantity > 0;

  ReactModal.setAppElement('body');

  function handleCancelButtonPress() {
    setShowDialogBox(false);
  }

  function handleYesRemoveThisPieceButtonPress() {
    let tempZones = JSON.parse(JSON.stringify(zones));

    tempZones[zoneIndex].layouts[layoutIndex].fields[
      fieldIndex - 1
    ].showremovepiecebutton = fieldIndex === 1 ? false : true;
    tempZones[zoneIndex].layouts[layoutIndex].fields[
      fieldIndex - 1
    ].showaddnextpiecebutton = true;

    if (
      tempZones[zoneIndex].layouts[layoutIndex].fields.length > 2 &&
      tempZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex - 1].title ===
        'Straight LED Strip'
    ) {
      tempZones[zoneIndex].layouts[layoutIndex].fields[
        fieldIndex - 1
      ].showendlayoutbutton = true;
    }

    if (
      tempZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].title ===
      'Straight LED Strip'
    ) {
      const currentStripLength =
        tempZones[zoneIndex].layouts[layoutIndex].stripLength;
      const currentLengthRemaining =
        tempZones[zoneIndex].layouts[layoutIndex].lengthRemaining;
      const fieldLength =
        tempZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].length;

      tempZones[zoneIndex].layouts[layoutIndex].stripLength =
        currentStripLength - fieldLength;
      tempZones[zoneIndex].layouts[layoutIndex].lengthRemaining =
        currentLengthRemaining + fieldLength;
    }

    tempZones[zoneIndex].layouts[layoutIndex].fields.splice(fieldIndex, 1);

    pageAttributes.designPage.currentFieldID =
      tempZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex - 1].id;

    setZones(tempZones);
    setShowDialogBox(false);
  }

  function handleYesRemoveThisLayoutButtonPress() {
    let tempZones = JSON.parse(JSON.stringify(zones));
    tempZones[zoneIndex].layouts.splice(layoutIndex, 1);
    tempZones[zoneIndex].layouts[layoutIndex - 1].current = true;
    pageAttributes.designPage.currentFieldID =
      'zone-' +
      (zoneIndex + 1) +
      '-layout-' +
      tempZones[zoneIndex].layouts[layoutIndex - 1].layoutNumber +
      '-quantity';

    pageAttributes.designPage.allLayoutsEnded = true;

    setZones(tempZones);
    setShowDialogBox(false);
  }

  function handleYesRemoveThisZoneButtonPress() {
    let tempZones = JSON.parse(JSON.stringify(zones));

    tempZones.splice(zoneIndex, 1);
    tempZones[zoneIndex - 1].current = true;
    pageAttributes.designPage.currentFieldID =
      'zone-' +
      (zoneIndex - 1) +
      '-layout-' +
      tempZones[zoneIndex - 1].layouts.length +
      '-quantity';

    pageAttributes.designPage.allLayoutsEnded = true;

    setZones(tempZones);
    setShowDialogBox(false);
  }

  function handleSetupCompleteStartCustomButtonClick() {}
  function handleSetupCompleteContinueCustomButtonClick() {}
  function handleSetupCompleteSingleButtonClick() {}
  function handleAddNextPieceButtonClick() {
    let tempZones = JSON.parse(JSON.stringify(zones));

    if (
      tempZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].title ===
        'Driver' ||
      tempZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].title ===
        'Other Driver' ||
      tempZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].title ===
        'Straight LED Strip'
    ) {
      AddPieceToLayoutZones(
        setupFieldChoices,
        tempZones,
        zoneIndex,
        layoutIndex,
        'Connector'
      );
    }

    if (
      tempZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].title ===
      'Connector'
    ) {
      AddPieceToLayoutZones(
        setupFieldChoices,
        tempZones,
        zoneIndex,
        layoutIndex,
        'Straight LED Strip'
      );
    }
    pageAttributes.designPage.currentFieldID =
      tempZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex + 1].id;
    setZones(tempZones);
  }
  function handleRemoveThisLayoutButtonClick() {
    setModalTitle('Are you sure?');
    setModalBody(
      <>
        <p>
          The following layout will be removed from your design. This cannot be
          undone.
        </p>
        <p className='large-emphasis'>
          {withRemote
            ? `Zone ` + (zoneIndex + 1) + `: Layout ` + (layoutIndex + 1)
            : `Layout ` + (layoutIndex + 1)}
        </p>{' '}
        <div className='button-group button-group--vertical'>
          <button
            onClick={() => handleYesRemoveThisLayoutButtonPress()}
            className='button button--danger'
          >
            Yes, remove this layout
          </button>
          <button
            onClick={() => {
              handleCancelButtonPress();
            }}
            className='button'
          >
            Cancel
          </button>
        </div>
      </>
    );

    setShowDialogBox(true);
  }
  function handleEndLayoutButtonClick() {
    let tempZones = JSON.parse(JSON.stringify(zones));

    pageAttributes.designPage.allLayoutsEnded = true;

    pageAttributes.designPage.currentFieldID =
      'zone-' +
      (zoneIndex + 1) +
      '-layout-' +
      tempZones[zoneIndex].layouts[layoutIndex].layoutNumber +
      '-quantity';
    tempZones[zoneIndex].layouts[layoutIndex].complete = true;
    tempZones[zoneIndex].layouts[layoutIndex].fields[
      fieldIndex
    ].showaddnextpiecebutton = false;
    tempZones[zoneIndex].layouts[layoutIndex].fields[
      fieldIndex
    ].showremovepiecebutton = false;
    tempZones[zoneIndex].layouts[layoutIndex].fields[
      fieldIndex
    ].showendlayoutbutton = false;
    tempZones[zoneIndex].layouts[layoutIndex].fields[
      fieldIndex
    ].showdeletelayoutbutton = false;
    setZones(tempZones);
  }
  function handleFinishDesignButtonClick() {
    pageAttributes.designPage.status = 'complete';
    pageAttributes.reviewPage.status = 'review required';
    pageAttributes.sendPage.status = 'review required';
  }
  function handleRemoveThisPieceButtonClick() {
    setModalTitle('Are you sure?');
    setModalBody(
      <>
        <p>
          The following piece will be removed from your design. This cannot be
          undone.
        </p>
        <p className='large-emphasis'>
          {withRemote
            ? `Zone ` +
              (zoneIndex + 1) +
              `: Layout ` +
              (layoutIndex + 1) +
              ` / Piece ` +
              (fieldIndex + 1)
            : `Layout ` + (layoutIndex + 1) + ` / Piece ` + (fieldIndex + 1)}
        </p>{' '}
        <div className='button-group button-group--vertical'>
          <button
            onClick={() => handleYesRemoveThisPieceButtonPress()}
            className='button button--danger'
          >
            Yes, remove the piece
          </button>
          <button
            onClick={() => {
              handleCancelButtonPress();
            }}
            className='button'
          >
            Cancel
          </button>
        </div>
      </>
    );

    setShowDialogBox(true);
  }
  function handleAddAnotherLayoutButtonClick() {

    let tempZones = JSON.parse(JSON.stringify(zones));

// console.log('in here', tempZones[zoneIndex].layouts[layoutIndex].lengthRemaining);
//     if (setupFieldChoices.ledType === 'White' && setupFieldChoices.driverRequired === 'JCC Driver' && tempZones[zoneIndex].layouts[layoutIndex].lengthRemaining > 100) {
//       dialogUseExistingDriverChoice()
//     } else {
//       addAnotherLayout();
//     }

    addAnotherLayout();

  }

  function dialogUseExistingDriverChoice() {
    let tempZones = JSON.parse(JSON.stringify(zones));

    setModalTitle('Use existing driver?');
    setModalBody(
      <>
        <p>
          Our driver will support another { tempZones[zoneIndex].layouts[layoutIndex].lengthRemaining / 100 + 'm' } of LED Strip. Do you want to continue using this for your next layout, or start with a new driver?
        </p>
        {/* <p className='large-emphasis' />{' '} */}
        <div className='' style={{ display: 'inline-flex', marginBottom: 20 }}>
          <button
            className="card button"
            onClick={() => {
              addAnotherLayout(tempZones[zoneIndex].layouts[layoutIndex].lengthRemaining);
              }}
          >
            <div class="card__top">
              <img style={{ maxWidth: '90%' }} src="/static/media/driver-nondim-IP20-30W.77285210.jpg" alt="Non-Dimmable Driver"></img>
            </div>
            <div class="card__text">
              <p class="card__title">Existing driver</p>
              <p class="card__description">{ tempZones[zoneIndex].layouts[layoutIndex].lengthRemaining / 100 + 'm' } remaining</p>
            </div>
          </button>
          <button
            class="card button"
            onClick={() => {
              addAnotherLayout();
              }}
          >
            <div class="card__top">
              <img style={{ maxWidth: '90%' }} src="/static/media/driver-nondim-IP20-30W.77285210.jpg" alt="Non-Dimmable Driver"></img>
            </div>
            <div class="card__text">
              <p class="card__title">Add a new driver</p>
              <p class="card__description">{ tempZones[zoneIndex].layouts[layoutIndex].maxLength / 100 + 'm' } capacity</p>
            </div>
          </button>
        </div>
        
        {/* <div className='button-group button-group--horizontal'>
          <button
            onClick={() => {
              addAnotherLayout(tempZones[zoneIndex].layouts[layoutIndex].lengthRemaining);
            }}
            className='button button--primary'
          >
            Use existing driver
          </button>
          <button
            onClick={() => {
              addAnotherLayout();
            }}
            className='button'
          >
            New driver
          </button>
        </div> */}
        <div className='button-group button-group--vertical'>
          <button
            onClick={() => {
              handleCancelButtonPress();
            }}
            className='button'
          >
            Cancel
          </button>
        </div>
      </>
    );

    setShowDialogBox(true);
  }


  function addAnotherLayout(fromPrevious = 0) {

    setShowDialogBox(false);
    let zonesTemplate = JSON.parse(JSON.stringify(zones));
    let tempZones = JSON.parse(JSON.stringify(zones));

    const nextLayoutNumber = parseInt(zones[zoneIndex].layouts.length) + 1;

    let nextLayout = zonesTemplate[0].layouts[0];
    pageAttributes.designPage.allLayoutsEnded = false;
    pageAttributes.designPage.status = 'incomplete';
    pageAttributes.reviewPage.status = 'design required';
    pageAttributes.sendPage.status = 'design required';

    nextLayout.id = 'zone-' + (zoneIndex + 1) + '-layout-' + nextLayoutNumber;
    nextLayout.layoutNumber = nextLayoutNumber;
    nextLayout.maxLenth = zonesTemplate[0].maxLength;

    if(fromPrevious > 0) {
      nextLayout.lengthRemaining = fromPrevious;
    } else {
      nextLayout.lengthRemaining = nextLayout.maxLength;
    }
    
    nextLayout.stripLength = 0;
    nextLayout.current = true;
    nextLayout.complete = false;
    nextLayout.quantity = 1;

    nextLayout.fields.splice(1);
    nextLayout.fields[0].id =
      'zone-' +
      (zoneIndex + 1) +
      '-layout-' +
      nextLayoutNumber +
      '-component-1';

    tempZones[zoneIndex].layouts[layoutIndex].current = false;
    nextLayout.fields[0].showaddnextpiecebutton = true;
    nextLayout.fields[0].showdeletelayoutbutton = true;

    tempZones[zoneIndex].layouts.push(nextLayout);

    setZones(tempZones);
  }

  function handleAddAnotherZoneButtonClick() {
    let zonesTemplate = JSON.parse(JSON.stringify(zones));
    let tempZones = JSON.parse(JSON.stringify(zones));

    const nextZoneNumber = parseInt(zones.length) + 1;

    let nextLayout = zonesTemplate[0].layouts[0];
    pageAttributes.designPage.allLayoutsEnded = false;
    pageAttributes.designPage.status = 'incomplete';
    pageAttributes.reviewPage.status = 'design required';
    pageAttributes.sendPage.status = 'design required';

    nextLayout.id = 'zone-' + nextZoneNumber + '-layout-1';
    nextLayout.layoutNumber = 1;
    nextLayout.maxLenth = zonesTemplate[0].maxLength;
    nextLayout.lengthRemaining = nextLayout.maxLength;
    nextLayout.stripLength = 0;
    nextLayout.current = true;
    nextLayout.complete = false;
    nextLayout.quantity = 1;

    nextLayout.fields.splice(1);
    nextLayout.fields[0].id =
      'zone-' + nextZoneNumber + '-layout-1-component-1';

    tempZones[zoneIndex].layouts[layoutIndex].current = false;
    nextLayout.fields[0].showaddnextpiecebutton = true;
    nextLayout.fields[0].showdeletelayoutbutton = false;

    tempZones.push({
      id: 'zone-' + nextZoneNumber,
      current: true,
      layouts: [],
    });

    tempZones[nextZoneNumber - 1].layouts.push(nextLayout);
    tempZones[zoneIndex].current = false;
    setZones(tempZones);
  }

  function handleRemoveThisZoneButtonClick() {
    setModalTitle('Are you sure?');
    setModalBody(
      <>
        <p>
          The following zone will be removed from your design. This cannot be
          undone.
        </p>
        <p className='large-emphasis'>Zone {zoneIndex + 1}</p>{' '}
        <div className='button-group button-group--vertical'>
          <button
            onClick={() => handleYesRemoveThisZoneButtonPress()}
            className='button button--danger'
          >
            Yes, remove this zone
          </button>
          <button
            onClick={() => {
              handleCancelButtonPress();
            }}
            className='button'
          >
            Cancel
          </button>
        </div>
      </>
    );

    setShowDialogBox(true);
  }

  function handleEditLayoutButtonClick() {
    let tempZones = JSON.parse(JSON.stringify(zones));

    pageAttributes.designPage.allLayoutsEnded = false;
    tempZones[zoneIndex].layouts[layoutIndex].complete = false;

    pageAttributes.designPage.status = 'incomplete';
    pageAttributes.reviewPage.status = 'design required';
    pageAttributes.sendPage.status = 'design required';

    pageAttributes.designPage.currentFieldID =
      tempZones[zoneIndex].layouts[layoutIndex].fields[
        tempZones[zoneIndex].layouts[layoutIndex].fields.length - 1
      ].id;

    tempZones[zoneIndex].layouts[layoutIndex].fields[
      tempZones[zoneIndex].layouts[layoutIndex].fields.length - 1
    ].showaddnextpiecebutton = true;
    tempZones[zoneIndex].layouts[layoutIndex].fields[
      tempZones[zoneIndex].layouts[layoutIndex].fields.length - 1
    ].showendlayoutbutton = true;
    tempZones[zoneIndex].layouts[layoutIndex].fields[
      tempZones[zoneIndex].layouts[layoutIndex].fields.length - 1
    ].showremovepiecebutton = true;

    if (layoutIndex > 0) {
      tempZones[zoneIndex].layouts[layoutIndex].fields[
        tempZones[zoneIndex].layouts[layoutIndex].fields.length - 1
      ].showdeletelayoutbutton = true;
    }

    setZones(tempZones);
  }

  return (
    <>
      <ReactModal
        isOpen={showDialogBox}
        style={{
          overlay: {
            backgroundColor: '#000',
            opacity: '0.8',
            zIndex: '1000000000000',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            borderRadius: 'none',
            border: 'none',
            marginRight: '-50%',
            minWidth: '320px',
            padding: '0px',
            opacity: '1.0',
            transform: 'translate(-50%, -50%)',
            zIndex: '1000000000000000',
          },
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
      >
        <div className='ReactModal__Content__Title'>
          <h3>{modalTitle}</h3>
        </div>
        <div className='ReactModal__Content__Body'>{modalBody}</div>
      </ReactModal>
      <div className='button-group'>
        {showSetupCompleteStartCustomButton && (
          <Link
            className='button button--tertiary button--next-page'
            to={{
              pathname: 'design',
              hash: '',
            }}
            onClick={() => handleSetupCompleteStartCustomButtonClick()}
          >
            Start your design
          </Link>
        )}
        {showSetupCompleteContinueCustomButton && (
          <Link
            className='button button--tertiary button--next-page'
            to={{
              pathname: 'design',
              hash: '',
            }}
            onClick={() => handleSetupCompleteContinueCustomButtonClick()}
          >
            Continue your design
          </Link>
        )}
        {showSetupCompleteSingleButton && (
          <Link
            className='button button--tertiary button--next-page'
            to={{
              pathname: 'review',
              hash: '',
            }}
            onClick={() => handleSetupCompleteSingleButtonClick()}
          >
            Review your design
          </Link>
        )}
        {showAddNextPieceButton && (
          <button
            className='button button--primary'
            onClick={() => handleAddNextPieceButtonClick()}
          >
            Add your next piece
          </button>
        )}
        {showEndLayoutButton && (
          <button
            className='button button--secondary'
            onClick={() => handleEndLayoutButtonClick()}
          >
            End layout
          </button>
        )}
        {showAddAnotherLayoutButton && (
          <button
            className='button button--secondary'
            onClick={() => handleAddAnotherLayoutButtonClick()}
          >
            Add another layout
          </button>
        )}
        {showAddAnotherZoneButton && (
          <button
            className='button button--secondary'
            onClick={() => handleAddAnotherZoneButtonClick()}
          >
            Add another zone
          </button>
        )}
        {showFinishDesignButton && (
          <Link
            className='button button--tertiary button--next-page'
            to={{
              pathname: 'review',
              hash: '',
            }}
            onClick={() => handleFinishDesignButtonClick()}
          >
            Finish your design
          </Link>
        )}
        <div className='button-group__secondary-buttons'>
          {showRemoveThisPieceButton && (
            <button
              className='button button--bordered'
              onClick={() => handleRemoveThisPieceButtonClick()}
            >
              Remove this piece
            </button>
          )}
          {showEditLayoutButton && (
            <button
              className='button button--bordered'
              onClick={() => handleEditLayoutButtonClick()}
            >
              Edit layout
            </button>
          )}
          {showRemoveThisLayoutButton && (
            <button
              className='button button--bordered'
              onClick={() => handleRemoveThisLayoutButtonClick()}
            >
              Delete layout
            </button>
          )}
          {showRemoveThisZoneButton && (
            <button
              className='button button--bordered'
              onClick={() => handleRemoveThisZoneButtonClick()}
            >
              Delete zone
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default FieldActionButtonsZones;
