import React, { useState, useLayoutEffect, useEffect } from 'react';
import classNames from 'classnames';
import useKeyPress from '../../../hooks/useKeyPress';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

const SetupCardInput = (props) => {
  const { fieldItem, enterPressed } = props;
  const [cardValue, setCardValue] = useState(fieldItem.length);
  const [inputValueExists, setInputValueExists] = useState(
    fieldItem.length > 0 ? true : false
  );
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [checkEnter, setCheckEnter] = useState(true);

  let targetElement = document.querySelector('#input-overlay');

  useLayoutEffect(() => {
    // eslint-disable-next-line
    targetElement = document.querySelector('#input-overlay');
  }, []);

  useEffect(() => {
    disableBodyScroll(targetElement);

    return () => {
      enableBodyScroll(targetElement);
      clearAllBodyScrollLocks();
    };
    // eslint-disable-next-line
  }, []);

  function handleValueChange(e) {
    setShowError(false);
    setCheckEnter(true);
    if (e.target.value.length > 0 && !inputValueExists) {
      setInputValueExists(true);
    }

    if (e.target.value.length === 0 && inputValueExists) {
      setInputValueExists(false);
    }

    setCardValue(parseInt(e.target.value));
  }

  function handleEnterClick() {
    if (cardValue % fieldItem.validationdivisor > 0) {
      setShowError(true);
      setErrorText(
        'Value must be in ' + fieldItem.validationdivisor + 'mm increments'
      );

      return;
    }

    if (cardValue > fieldItem.validationlimit) {
      setShowError(true);

      if (
        fieldItem.id === 'rgbw-remote-handheld' ||
        fieldItem.id === 'rgbw-remote-wall-mounted'
      ) {
        setErrorText('Value must be either 1 or 2');
      } else {
        setErrorText(
          'Length must not exceed ' + fieldItem.validationlimit + 'mm'
        );
      }
      return;
    }

    if (cardValue > 0) {
      return enterPressed(cardValue);
    }
    return;
  }

  // Handle Enter key press
  const enterPress = useKeyPress('Enter');
  if (checkEnter && enterPress && cardValue > 0) {
    handleEnterClick();
    setCheckEnter(false);
  }

  const inputOverlayClass = classNames({
    'input-overlay': true,
    active: true,
    'input-value-exists': inputValueExists,
    'input-failure': showError,
  });

  return (
    <div className={inputOverlayClass} id='input-overlay'>
      <p className='input-overlay__label'>
        {(fieldItem.id === 'rgbw-remote-handheld' ||
          fieldItem.id === 'rgbw-remote-wall-mounted') &&
          `Would you like 1 or 2 remote units?`}
        {fieldItem.id === 'layout-type-single-length' &&
          `Enter length to the nearest ` + fieldItem.validationdivisor + `mm`}
      </p>

      {(fieldItem.id === 'rgbw-remote-handheld' ||
        fieldItem.id === 'rgbw-remote-wall-mounted') && (
        <div className='input-overlay__quantity-field'>
          <input
            type='text'
            pattern='\d*' // this shows numeric keyboard on mobile https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input
            value={cardValue > 0 ? cardValue : ''}
            className='input-overlay__input'
            onChange={(input) => handleValueChange(input)}
            onBlur={() => {}}
            autoFocus
          />
        </div>
      )}
      {fieldItem.id === 'layout-type-single-length' && (
        <div className='input-overlay__field'>
          <input
            type='text'
            pattern='\d*' // this shows numeric keyboard on mobile https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input
            value={cardValue > 0 ? cardValue : ''}
            className='input-overlay__input'
            onChange={(input) => handleValueChange(input)}
            onBlur={() => {}}
            autoFocus
          />
        </div>
      )}

      <div className='input-overlay__actions'>
        <button
          className='input-overlay__submit button button--bordered'
          onClick={() => handleEnterClick()}
        >
          Enter
        </button>
        {showError && (
          <p
            className='input-overlay__message input-overlay__message--failure'
            data-part-input-message=''
          >
            {errorText}
          </p>
        )}
      </div>
    </div>
  );
};

export default SetupCardInput;
