import { useEffect, useGlobal } from 'reactn';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const NavCleanup = ({ location: { pathname } }) => {
  const [, setMobileDrawerOpen] = useGlobal('mobileDrawerOpen');

  useEffect(() => {
    setMobileDrawerOpen(false);
    setTimeout(() => {
      document.body.classList.remove('mobile-menu-open');
      document.body.style.overflow = '';
    }, 500);
    // eslint-disable-next-line
  }, [pathname]);

  return null;
};

NavCleanup.propTypes = {
  hash: PropTypes.string
};

export default withRouter(NavCleanup);
