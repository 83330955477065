import React, { useState, useGlobal } from 'reactn';

const MobileDrawer = (props) => {
  const { title, content } = props;

  const [mobileDrawerOpen, setMobileDrawerOpen] = useGlobal('mobileDrawerOpen');
  const [drawerOpen, setDrawerOpen] = useState(mobileDrawerOpen);

  function handleMobileDrawerTriggerClick() {
    if (!drawerOpen) {
      document.body.style.overflow = 'hidden';
      document.body.classList.add('mobile-drawer-open');
    } else {
      document.body.style.overflow = '';
      document.body.classList.remove('mobile-drawer-open');
    }

    setMobileDrawerOpen(!drawerOpen);
    setDrawerOpen(!drawerOpen);
  }

  return (
    <div
      className={
        mobileDrawerOpen
          ? 'mobile-drawer mobile-menu-push open'
          : 'mobile-drawer mobile-menu-push'
      }
    >
      <button
        className='mobile-drawer__trigger'
        onClick={() => {
          handleMobileDrawerTriggerClick();
        }}
      >
        {title}
      </button>
      <div className='mobile-drawer__wrapper'>
        <div className='mobile-drawer__content'>{content}</div>
      </div>
    </div>
  );
};

export default MobileDrawer;
