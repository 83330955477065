import React, { setGlobal } from 'reactn';

const MobileHeader = props => {
  const { title, currentFieldNumber, totalFields, currentLayout } = props;

  function handleMobileMenuOpen() {
    document.body.classList.add('mobile-menu-open');
    setGlobal({ mobileMenuOpen: true });
  }

  return (
    <div className='mobile-header mobile-menu-push'>
      <h1>{title}</h1>
      <span className='field-step'>
        {currentFieldNumber ? currentFieldNumber + `/` + totalFields : ''}
        {currentLayout && (
          <span class='field-step__layout'>(Layout {currentLayout})</span>
        )}
      </span>
      <button
        className='mobile-menu-trigger'
        onClick={() => handleMobileMenuOpen()}
      />
    </div>
  );
};

export default MobileHeader;
