import React, { useGlobal } from 'reactn';
import _ from 'lodash';
import Timeline from '../../common/timeline';
import SideBar from '../../common/sideBar';
import PageContentWrapper from '../../common/pageContentWrapper';
import SetupPageContent from './setupPageContent';
import AppFooter from '../../common/appFooter';
import MobileHeader from '../../common/mobileHeader';
import MobileDrawer from '../../common/mobileDrawer';
import MobileMenu from '../../common/mobileMenu';
import setupFieldsWhite from '../../../config/setupfieldswhite.json';
import setupFieldsRGBW from '../../../config/setupfieldsrgbw.json';
import CreateCustomZoneLayout from '../../common/createCustomZoneLayout';
import CreateSingleLengthZoneLayout from '../../common/createSingleLengthZoneLayout';
import ScrollToElement from '../../common/scrollToElement';

const SetupPage = (props) => {
  const { mobile, tablet, desktop, reactGA } = props;

  const [global] = useGlobal();
  const [pageAttributes, setPageAttributes] = useGlobal('pageAttributes');
  const [setupFields, setSetupFields] = useGlobal('setupFields');
  const [setupFieldChoices] = useGlobal('setupFieldChoices');
  const [zones, setZones] = useGlobal('zones');
  const [showMobileFieldInput] = useGlobal('showMobileFieldInput');

  reactGA.pageview(window.location.pathname + window.location.search);

  if (
    setupFieldChoices.ledType === 'White' &&
    pageAttributes.setupPage.removeDimming
  ) {
    let tempSetupFields = setupFields;

    if (setupFields.length === 8) {
      tempSetupFields.splice(5, 1);
    }

    pageAttributes.setupPage.removeDimming = false;

    if (tempSetupFields[5].disabled) {
      tempSetupFields[5].disabled = false;
      pageAttributes.setupPage.postSwapNavigationTarget = tempSetupFields[5].id;
    }
    let newSetupFields = JSON.parse(JSON.stringify(tempSetupFields));
    setSetupFields(newSetupFields);
    setPageAttributes(pageAttributes);
    ScrollToElement('profile', mobile);
  }

  if (
    setupFieldChoices.ledType === 'White' &&
    pageAttributes.setupPage.addDimming
  ) {
    let tempSetupFields = setupFields;

    if (setupFields.length === 7) {
      tempSetupFields.splice(5, 0, {
        id: 'dimming',
        current: false,
        disabled: false,
        title: 'Choose dimming',
        shorttitle: 'dimming',
        value: '',
        instruction: 'Select the dimming option you require for your driver.',
        fieldnote: '',
        options: [
          {
            id: 'dimming-triac',
            selected: false,
            deselected: false,
            title: 'Dimmable (Triac)',
            description: 'Typical domestic use',
            imagepath: 'dimming-triac-or-1-10v.jpg',
          },
          {
            id: 'dimming-non',
            selected: false,
            deselected: false,
            title: 'Non-dimmable',
            description: 'Fixed light output',
            imagepath: 'dimming-non-dimmable.jpg',
          },
        ],
      });
    }

    pageAttributes.setupPage.addDimming = false;

    if (tempSetupFields[5].disabled) {
      tempSetupFields[5].disabled = false;
      pageAttributes.setupPage.postSwapNavigationTarget = tempSetupFields[5].id;
    }

    let newSetupFields = JSON.parse(JSON.stringify(tempSetupFields));

    setSetupFields(newSetupFields);
    setPageAttributes(pageAttributes);
    setTimeout(() => {
      ScrollToElement('dimming', mobile);
    }, 0);

    
  }

  if (pageAttributes.setupPage.swapSetupFields) {
    let newSetupFields = JSON.parse(JSON.stringify(setupFields));

    if (pageAttributes.setupPage.setupFieldSet === 'led-type-white') {
      const tempSetupFields = setupFieldsWhite;
      newSetupFields = JSON.parse(JSON.stringify(tempSetupFields));
    }
    if (pageAttributes.setupPage.setupFieldSet === 'led-type-rgbw') {
      const tempSetupFields = setupFieldsRGBW;
      newSetupFields = JSON.parse(JSON.stringify(tempSetupFields));
    }

    let newZones = JSON.parse(JSON.stringify(zones));
    newZones.splice(0, newZones.length);
    setZones(newZones);        
    setSetupFields(newSetupFields);
    pageAttributes.setupPage.swapSetupFields = false;
    pageAttributes.setupPage.navigateAfterSwap = true;
  }

  if (pageAttributes.setupPage.navigateAfterSwap && setupFields[1]) {
    setTimeout(() => {
      const fieldID = setupFields[1].id;
      ScrollToElement(fieldID, mobile, 200);
      pageAttributes.setupPage.navigateAfterSwap = false;
    }, 800);
  }

  if (pageAttributes.setupPage.postSwapNavigationTarget !== '') {
    setTimeout(() => {
      const fieldID = pageAttributes.setupPage.postSwapNavigationTarget;
      ScrollToElement(fieldID, mobile, 200);
      pageAttributes.setupPage.postSwapNavigationTarget = '';
    }, 800);
  }

  // Determine current, previous and next fields on this page
  const totalFields = setupFields.length;
  let currentFieldIndex = setupFields.findIndex(
    (field) => field.current === true
  );
  if (currentFieldIndex > -1) {
    pageAttributes.setupPage.currentFieldID = setupFields[currentFieldIndex].id;

    if (currentFieldIndex > 0) {
      pageAttributes.setupPage.previousFieldID =
        setupFields[currentFieldIndex - 1].id;
    } else {
      pageAttributes.setupPage.previousFieldID = '';
    }

    if (
      totalFields > currentFieldIndex + 1 &&
      !setupFields[currentFieldIndex + 1].disabled
    ) {
      pageAttributes.setupPage.nextFieldID =
        setupFields[currentFieldIndex + 1].id;
    } else {
      pageAttributes.setupPage.nextFieldID = '';
    }
  }

  // Determine complete status of this page
  if (setupFields.length > 2) {
    if (
      setupFields[setupFields.length - 1].value === 'Custom Design' ||
      (setupFields[setupFields.length - 1].value === 'Single Length' &&
        setupFields[setupFields.length - 1].length > 0)
    ) {
      pageAttributes.setupPage.status = 'complete';
    } else {
      pageAttributes.setupPage.status = 'incomplete';
      pageAttributes.designPage.status = 'setup required';
      pageAttributes.reviewPage.status = 'setup required';
      pageAttributes.sendPage.status = 'setup required';
    }
  }

  function UpdateSetupFieldChoices() {
    setupFieldChoices.stripLength = setupFields[setupFields.length - 1].length;
    setupFieldChoices.environment = _.find(setupFields, {
      id: 'environment',
    }).iprating;
    setupFieldChoices.profile = _.find(setupFields, { id: 'profile' }).value;
    setupFieldChoices.ledType = _.find(setupFields, {
      id: 'led-type',
    }).value;
    setupFieldChoices.customLayout =
      _.find(setupFields, {
        id: 'layout-type',
      }).value === 'Custom Design';

    if (setupFieldChoices.ledType === 'White') {
      setupFieldChoices.colourTemp = _.find(setupFields, {
        id: 'colour-temp-white',
      }).value;
      setupFieldChoices.wattage = _.find(setupFields, {
        id: 'wattage-white',
      }).wattage;
      setupFieldChoices.driverRequired = _.find(setupFields, {
        id: 'driver-required',
      }).value;

      if (setupFieldChoices.driverRequired === 'JCC Driver') {
        setupFieldChoices.dimming = _.find(setupFields, {
          id: 'dimming',
        }).value;
      }

      setupFieldChoices.remoteType = '';
      setupFieldChoices.remoteQuantity = 0;
    } else {
      setupFieldChoices.remoteType = _.find(setupFields, {
        id: 'rgbw-remote',
      }).value;
      setupFieldChoices.remoteQuantity = _.find(setupFields, {
        id: 'rgbw-remote',
      }).length;
      setupFieldChoices.colourTemp = _.find(setupFields, {
        id: 'colour-temp-rgbw',
      }).value;
      setupFieldChoices.wattage = _.find(setupFields, {
        id: 'wattage-rgbw',
      }).wattage;
      setupFieldChoices.driverRequired = _.find(setupFields, {
        id: 'driver-required',
      }).value;
    }
  }

  if (pageAttributes.setupPage.createLayout) {
    let newZoneLayout = [];
    UpdateSetupFieldChoices();
    if (pageAttributes.setupPage.singleLength) {
      newZoneLayout = CreateSingleLengthZoneLayout(setupFieldChoices);
      pageAttributes.setupPage.createLayout = false;
      pageAttributes.designPage.status = 'complete';
      pageAttributes.reviewPage.status = 'incomplete';
      pageAttributes.sendPage.status = 'review required';
    } else {
      newZoneLayout = CreateCustomZoneLayout(setupFieldChoices);
      pageAttributes.setupPage.createLayout = false;
      pageAttributes.designPage.status = 'incomplete';
      pageAttributes.reviewPage.status = 'design required';
      pageAttributes.sendPage.status = 'design required';
    }

    setZones(newZoneLayout);

    sessionStorage.setItem('jcc-global-state', JSON.stringify(global));
  }

  const sidebarContent = (
    <>
      {mobile && (
        <div className='mobile-drawer__instruction'>
          <p className='lead'>Your setup configuration</p>
        </div>
      )}
      {desktop && (
        <div className='sidebar__instruction'>
          <p className='lead'>Configure your LED&nbsp;Strip setup</p>
          <p>
            Specify your setup requirements to configure the specification of
            your LED&nbsp;Strip design.
          </p>
        </div>
      )}
      <Timeline timelineItems={setupFields} />
    </>
  );

  return (
    <>
      <div className='page'>
        {mobile && (
          <>
            <MobileHeader
              title='Setup'
              currentFieldNumber={currentFieldIndex + 1}
              totalFields={totalFields}
            />
          </>
        )}
        {desktop && <SideBar title='Setup' content={sidebarContent} />}
        <PageContentWrapper
          content={
            <SetupPageContent
              fields={setupFields}
              fieldsUpdateFunction={(setupFields) =>
                setSetupFields(setupFields)
              }
              showMobileFieldInput={showMobileFieldInput}
              mobile={mobile}
              tablet={tablet}
              desktop={desktop}
              pageAttributes={pageAttributes}
            />
          }
        />
        {mobile && (
          <MobileDrawer title='Setup Summary' content={sidebarContent} />
        )}
        {desktop && <AppFooter desktop={true} />}
      </div>
      {mobile && <MobileMenu />}
    </>
  );
};

export default SetupPage;
