import React from 'react';
import SetupField from './setupField';
import Carousel from './setupCarousel';

const SetupPageContent = props => {
  const {
    fields,
    fieldsUpdateFunction,
    mobile,
    tablet,
    desktop,
    pageAttributes
  } = props;

  return (
    <div className='page-content__section'>
      <div className='field-set'>
        {fields.map(
          (field, index) =>
            !fields[index].disabled && (
              <SetupField
                fields={fields}
                fieldsUpdateFunction={fieldsUpdateFunction}
                fieldIndex={index}
                key={field.id}
                mobile={mobile}
                desktop={desktop}
                previousFieldID={pageAttributes.setupPage.previousFieldID}
                nextFieldID={pageAttributes.setupPage.nextFieldID}
                content={
                  <Carousel
                    field={field}
                    fields={fields}
                    fieldsUpdateFunction={fieldsUpdateFunction}
                    fieldIndex={index}
                    mobile={mobile}
                    tablet={tablet}
                    desktop={desktop}
                    currentPage='setup'
                    pageAttributes={pageAttributes}
                  />
                }
              />
            )
        )}
      </div>
    </div>
  );
};

export default SetupPageContent;
