import React from 'react';
import { Link } from 'react-router-dom';
import InitiateGlobalState from '../../common/initiateGlobalState';
import logo from '../../../graphics/JCC-Leviton-logo-600px.png';

const ConfirmationPage = (props) => {
  const { reactGA } = props;
  reactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <div className='splash splash--right'>
        <div className='splash__detail'>
          <div className='splash__detail__content'>
            <img className='logo' src={logo} alt='JCC Leviton logo' />
            <h1>Thank You</h1>
            <p className='lead ie-hide'>Please check your email.</p>
            <p className='ie-hide'>
              To order your LED Strip kit, provide a copy of your design summary
              email to your chosen wholesaler. If you have any further questions
              please contact customer services on 01243&nbsp;838999, or{' '}
              <a href='mailto:customer.services@jcc.co.uk?subject=JCC LED Strip Configurator Support Request'>
                customer.services@jcc.co.uk
              </a>
              .
            </p>
            <div className='button-group button-group--centered'>
              <Link
                className='button button--primary reset-link'
                to={{
                  pathname: '/setup',
                  hash: '',
                }}
                onClick={() => InitiateGlobalState(true, false)}
              >
                Create another kit
              </Link>
            </div>
            {/* <p className='lead ie-show'>You are using an unsupported browser.</p>
            <p className='lead ie-show'>
              Please{' '}
              <a href='https://browsehappy.com/?locale=en'>
                upgrade your browser
              </a>{' '}
              to use the configurator.
            </p> */}
          </div>
          <div className='splash__detail__footer'>
            <small>
              <a href='https://www.jcc.co.uk'>View the JCC website</a>
            </small>
          </div>
        </div>
        <div className='splash__graphic' />
      </div>
    </>
  );
};

export default ConfirmationPage;
