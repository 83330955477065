import React, { useGlobal, useState } from 'reactn';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import ScrollToElement from '../../common/scrollToElement';
import ReviewFieldZones from './reviewFieldZones';
import ReviewTableZones from './reviewTableZones';

const ReviewPageContentZones = (props) => {
  const {
    mobile,
    desktop,
    pageAttributes,
    updatedZoneLayouts,
    confirmDesign,
  } = props;

  const [setupFieldChoices] = useGlobal('setupFieldChoices');
  const [tradeDiscount, setTradeDiscount] = useGlobal('tradeDiscount');
  const [canConfirm] = useState(setupFieldChoices);
  const [, setDisabledConfirmClick] = useState(false);

  let remotePrice = 0;
  if (setupFieldChoices.remoteType === 'Handheld Remote') {
    remotePrice = setupFieldChoices.remoteQuantity * 90;
  }
  if (setupFieldChoices.remoteType === 'Wall Mounted Remote') {
    remotePrice = setupFieldChoices.remoteQuantity * 170;
  }

  const withRemote = remotePrice > 0;

  let numZones = updatedZoneLayouts.length;
  let numLastLayout =
    updatedZoneLayouts[updatedZoneLayouts.length - 1].layouts.length;

  function handleDisabledConfirmClick() {
    setDisabledConfirmClick(true);
  }

  // function canISend() {
  //   if (
  //     customerName.length > 1 &&
  //     !customerNameError &&
  //     customerEmail.length > 3 &&
  //     !customerEmailError
  //   ) {
  //     setCanSend(true);
  //     setDisabledConfirmClick(false);
  //     return;
  //   }
  //   setCanSend(false);
  // }

  function calculateTotalPrice() {
    let totalPrice = 0;
    updatedZoneLayouts.map((zone) => {
      zone.layouts.map((layout) => {
        totalPrice = totalPrice + layout.quantity * layout.price;
        return null;
      });
      return null;
    });

    totalPrice = totalPrice + remotePrice;

    return (
      totalPrice - totalPrice * ((tradeDiscount >= 0 ? tradeDiscount : 0) / 100)
    );
  }

  function handleTradeDiscountChange(e) {
    if (e.target.value >= 0 && e.target.value <= 100) {
      setTradeDiscount(parseFloat(e.target.value).toFixed(1) * 1);
    }
  }

  return (
    <>
      <div className='page-content__section'>
        <div className='field-set' key='field-set-1'>
          {setupFieldChoices.ledType === 'RGBW' && (
            <div className='field field--slim current' id='rgbw-block'>
              <div className='field__content-wrapper'>
                {desktop && (
                  <div className='field__nav'>
                    <button className='field__nav__prev' disabled>
                      &lt;
                    </button>
                    <div className='field__number field__number' />
                    <button
                      className='field__nav__next'
                      onClick={() =>
                        ScrollToElement('zone-1-layout-1', mobile, 200)
                      }
                    >
                      &gt;
                    </button>
                  </div>
                )}
                <Waypoint
                  onEnter={({ previousPosition, currentPosition, event }) => {
                    if (Waypoint.inside) {
                      window.history.replaceState(
                        undefined,
                        undefined,
                        '#ready-to-order'
                      );
                    }
                  }}
                  scrollableAncestor={window}
                  fireOnRapidScroll={false}
                  topOffset='55%'
                  bottomOffset='30%'
                  debug={false}
                >
                  <div className='field__block'>
                    <div className='field__header'>
                      {mobile && (
                        <div className='field__number field__number' />
                      )}
                      <h2 className='field__title'>
                        RGBW Design Configuration
                      </h2>
                      <p className='field__instruction'>
                        Thank you for choosing JCC RGBW strip as your LED strip.
                      </p>
                      {setupFieldChoices.remoteType ===
                        'No Remote or Controller Required' && (
                        <>
                          <p className='field__instruction'>
                            You haven't selected a JCC RGBW Remote. The JCC
                            Handheld Remote can support up to 3 Zones
                            controlling up to 10 Layouts each and the JCC Wall
                            Mounted Remote can support up to 4 Zones controlling
                            up to 10 Layouts each.
                          </p>
                        </>
                      )}
                      {setupFieldChoices.remoteType !==
                        'No Remote or Controller Required' &&
                        setupFieldChoices.remoteType !== '' && (
                          <p className='field__instruction'>
                            You have selected to pair your RGBW layouts with the
                            following JCC remote. The JCC Handheld Remote can
                            support up to 3 Zones controlling up to 10 Layouts
                            each and the JCC Wall Mounted Remote can support up
                            to 4 Zones controlling up to 10 Layouts each.
                          </p>
                        )}
                    </div>
                    <div className='field__content'>
                      {setupFieldChoices.remoteType !==
                        'No Remote or Controller Required' &&
                        setupFieldChoices.remoteType !== '' && (
                          <>
                            <table className='component-table'>
                              <thead>
                                <tr className='component-table__header component-table__row--three'>
                                  <td
                                    className='component-table__cell'
                                    data-table-header='Product'
                                  >
                                    Type
                                  </td>
                                  <td
                                    className='component-table__cell'
                                    data-table-header='Wattage'
                                  >
                                    Quantity
                                  </td>
                                  <td
                                    className='component-table__cell'
                                    data-table-header='Price'
                                  >
                                    Price
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className='component-table__row component-table__row--three'>
                                  <td
                                    className='component-table__cell'
                                    data-table-header='Product'
                                  >
                                    {setupFieldChoices.remoteType}
                                  </td>
                                  <td
                                    className='component-table__cell'
                                    data-table-header='Quantity'
                                  >
                                    {setupFieldChoices.remoteQuantity}
                                  </td>
                                  <td
                                    className='component-table__cell'
                                    data-table-header='Price'
                                  >
                                    <strong>£{remotePrice.toFixed(2)}</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                    </div>
                  </div>
                </Waypoint>
              </div>
            </div>
          )}
          {updatedZoneLayouts.map((zone, zoneIndex) =>
            zone.layouts.map((layout, layoutIndex) => (
              <ReviewFieldZones
                zoneIndex={zoneIndex}
                layoutIndex={layoutIndex}
                key={`zone-` + zoneIndex + `layout-` + layout.layoutNumber}
                mobile={mobile}
                desktop={desktop}
                previousFieldID={pageAttributes.reviewPage.previousFieldID}
                nextFieldID={pageAttributes.reviewPage.nextFieldID}
                content={
                  <ReviewTableZones
                    layout={layout}
                    zoneIndex={zoneIndex}
                    layoutIndex={layoutIndex}
                  />
                }
              />
            ))
          )}
          <div className='field field--slim current' id='ready-to-order'>
            <div className='field__content-wrapper'>
              {desktop && (
                <div className='field__nav'>
                  <button
                    className='field__nav__prev'
                    onClick={() =>
                      ScrollToElement(
                        'zone-' + numZones + '-layout-' + numLastLayout,
                        mobile,
                        200
                      )
                    }
                  >
                    &lt;
                  </button>
                  <div className='field__number field__number--tick' />
                  <button className='field__nav__next' disabled>
                    &gt;
                  </button>
                </div>
              )}
              <Waypoint
                onEnter={({ previousPosition, currentPosition, event }) => {
                  if (Waypoint.inside) {
                    window.history.replaceState(
                      undefined,
                      undefined,
                      '#ready-to-order'
                    );
                  }
                }}
                scrollableAncestor={window}
                fireOnRapidScroll={false}
                topOffset='55%'
                bottomOffset='30%'
                debug={false}
              >
                <div className='field__block'>
                  <div className='field__header'>
                    {mobile && (
                      <div className='field__number field__number--tick' />
                    )}
                    <h2 className='field__title'>Ready to order?</h2>
                    <p className='field__instruction'>
                      Thank you for configuring your LED Strip design. Please
                      confirm your design to proceed to the send screen where
                      you will be able to send your design to an electrical
                      wholesaler, who will provide you with a quote.
                    </p>
                  </div>
                  <div className='field__content'>
                    <table className='component-table'>
                      <thead>
                        <tr className='component-table__header component-table__row--three'>
                          <td
                            className='component-table__cell'
                            data-table-header='Product'
                          >
                            Design Elements
                          </td>
                          <td
                            className='component-table__cell'
                            data-table-header='Wattage'
                          >
                            Quantity
                          </td>
                          <td
                            className='component-table__cell'
                            data-table-header='Price'
                          >
                            Price
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {setupFieldChoices.remoteType !==
                          'No Remote or Controller Required' &&
                          setupFieldChoices.remoteType !== '' && (
                            <tr className='component-table__row component-table__row--three'>
                              <td
                                className='component-table__cell'
                                data-table-header='Product'
                              >
                                {setupFieldChoices.remoteType}
                              </td>
                              <td
                                className='component-table__cell'
                                data-table-header='Quantity'
                              >
                                {setupFieldChoices.remoteQuantity}
                              </td>
                              <td
                                className='component-table__cell'
                                data-table-header='Price'
                              >
                                <strong>£{remotePrice.toFixed(2)}</strong>
                              </td>
                            </tr>
                          )}

                        {updatedZoneLayouts.map((zone, zoneIndex) =>
                          zone.layouts.map((layout) => (
                            <tr
                              className='component-table__row component-table__row--three'
                              key={
                                `zone-` +
                                zoneIndex +
                                `-layout-` +
                                layout.layoutNumber +
                                '-row'
                              }
                            >
                              <td
                                className='component-table__cell'
                                data-table-header='Product'
                              >
                                {withRemote
                                  ? `Zone ` +
                                    (zoneIndex + 1) +
                                    `: Layout ` +
                                    layout.layoutNumber
                                  : `Layout ` + layout.layoutNumber}
                              </td>
                              <td
                                className='component-table__cell'
                                data-table-header='Quantity'
                              >
                                {layout.quantity}
                              </td>
                              <td
                                className='component-table__cell'
                                data-table-header='Price'
                              >
                                <strong>
                                  £{(layout.quantity * layout.price).toFixed(2)}
                                </strong>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                      <tfoot className='component-table__footer component-table__row--two'>
                        <tr className='component-table__row component-table__row--discountpercent'>
                          <td
                            className='component-table__cell'
                            data-table-header='Product'
                          >
                            Trade Discount{' '}
                            <span>
                              Add your trade discount here if relevant
                            </span>
                          </td>
                          <td
                            className='component-table__cell component-table__cell--input'
                            data-table-header='Percentage'
                          >
                            <div className='input-submit'>
                              <input
                                type='number'
                                id='disc'
                                pattern='\d*' // this shows numeric keyboard on mobile https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input
                                noValidate // see https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input#answer-15480933
                                className='input-submit__input'
                                min='0'
                                max='100'
                                step='1'
                                onChange={(input) =>
                                  handleTradeDiscountChange(input)
                                }
                                value={tradeDiscount}
                              />
                              <button className='input-submit__button'>
                                Ok
                              </button>
                              <span className='input-submit__span'>&#37;</span>
                            </div>
                          </td>
                        </tr>
                        <tr className='component-table__row component-table__row--two'>
                          <td
                            className='component-table__cell'
                            data-table-header='Product'
                          >
                            Total Price{' '}
                            <span>
                              Combined price of all layouts (including discount
                              if relevant).
                              <span style={{ marginLeft: 3, color: '#76b900' }}>
                                Prices are valid for 30 days.
                              </span>
                            </span>
                          </td>
                          <td
                            className='component-table__cell'
                            data-table-header='Price'
                          >
                            <strong>£{calculateTotalPrice().toFixed(2)}</strong>
                          </td>
                        </tr>
                        <tr className='component-table__row component-table__row--discountmsg'>
                          <td className='component-table__cell'>
                            Remember to enter your wholesaler discount to see
                            accurate pricing
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <div className='button-group'>
                      {canConfirm ? (
                        <Link
                          className='button button--tertiary button--next-page'
                          to={{
                            pathname: 'send',
                            hash: '',
                          }}
                          onClick={confirmDesign}
                        >
                          Confirm design
                        </Link>
                      ) : (
                        <div
                          className='button button--tertiary button--next-page'
                          disabled
                          onClick={() => handleDisabledConfirmClick()}
                        >
                          Confirm design
                        </div>
                      )}

                      {/* <Link
                        className='button button--tertiary button--next-page'
                        to={{
                          pathname: 'send',
                          hash: '',
                        }}
                        onClick={confirmDesign}
                      >
                        Confirm design
                      </Link> */}
                    </div>
                  </div>
                </div>
              </Waypoint>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewPageContentZones;
