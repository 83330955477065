import React from 'react';
import PageNav from './pageNav';

const AppFooter = () => {
  return (
    <footer className='page-footer'>
      <PageNav desktop={true} />
    </footer>
  );
};

export default AppFooter;
