import React, { useGlobal, setGlobal, useEffect } from 'reactn';
import SideBar from '../../common/sideBar';
import PageContentWrapper from '../../common/pageContentWrapper';
import SendPageContent from './sendPageContent';
import AppFooter from '../../common/appFooter';
import MobileHeader from '../../common/mobileHeader';
import MobileMenu from '../../common/mobileMenu';
import ScrollToElement from '../../common/scrollToElement';

const SendPage = (props) => {
  const { mobile, desktop, reactGA } = props;
  const [zones] = useGlobal('zones');

  reactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    if (zones.length === 0) {
      window.location.href = '/';
      return;
    }
    ScrollToElement('top', 0);
    // eslint-disable-next-line
  }, []);

  const sidebarContent = (
    <>
      <div className='sidebar__instruction'>
        <p className='lead'>How to order your LED&nbsp;Strip products</p>
        <ol>
          <li>Enter your details to receive your kit design by&nbsp;email.</li>
          <li>
            Your email will contain a unique reference number and the full
            component&nbsp;list.
          </li>
          <li>
            Present the email to your wholesaler to request a quote or place
            an&nbsp;order.
          </li>
        </ol>
      </div>
    </>
  );

  function handleMobileMenuOpen() {
    document.body.classList.add('mobile-menu-open');
    setGlobal({ mobileMenuOpen: true });
  }

  return (
    <>
      <div className='page'>
        {mobile && (
          <MobileHeader
            title='Send'
            onClickHandler={() => handleMobileMenuOpen()}
          />
        )}
        {desktop && <SideBar title='Send' content={sidebarContent} />}
        <PageContentWrapper
          content={<SendPageContent mobile={mobile} desktop={desktop} />}
        />
        {desktop && <AppFooter desktop={true} />}
      </div>
      {mobile && <MobileMenu />}
    </>
  );
};

export default SendPage;
