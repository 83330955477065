import React from 'react';
import AppTitle from './appTitle';
import AppActions from './appActions';

const SideBar = props => {
  const { title, content } = props;

  return (
    <header className='sidebar'>
      <AppTitle />
      <div className='sidebar__main'>
        <h2>{title}</h2>
        {content}
      </div>
      <AppActions />
    </header>
  );
};

export default SideBar;
