import { useEffect, useGlobal } from 'reactn';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const ScrollHandler = ({ location: { hash } }, mobile) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useGlobal('mobileDrawerOpen');

  useEffect(() => {
    const element = document.getElementById(hash.replace('#', ''));

    if (element && mobileDrawerOpen) {
      window.scrollTo({
        top: element.offsetTop - (mobile ? 64 : 0),
        left: 0,
        behavior: 'auto'
      });

      setTimeout(() => {
        setMobileDrawerOpen(false);
        document.body.style.overflow = '';
        document.body.classList.remove('mobile-drawer-open');
      }, 1000);
    } else if (element) {
      setTimeout(() => {
        window.scrollTo({
          top: element.offsetTop - (mobile ? 64 : 0),
          left: 0,
          behavior: 'smooth'
        });
      }, 100);
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [hash, mobile]);

  return null;
};

ScrollHandler.propTypes = {
  hash: PropTypes.string
};

export default withRouter(ScrollHandler);
