import React, { Fragment, useState, useGlobal } from 'reactn';
import useKeyPress from '../../../hooks/useKeyPress';

const ReviewTableZones = (props) => {
  const { layout, zoneIndex, layoutIndex } = props;

  const [zones, setZones] = useGlobal('zones');
  const [layoutQuantity, setLayoutQuantity] = useState(
    zones[zoneIndex].layouts[layoutIndex].quantity
  );
  const [global] = useGlobal();
  const [setupFieldChoices] = useGlobal('setupFieldChoices');
  const withRemote = setupFieldChoices.remoteQuantity > 0;
  const [checkEnter, setCheckEnter] = useState(true);

  function handleEnterClick() {
    if (checkEnter) {
      let tempZones = JSON.parse(JSON.stringify(zones));
      let totalLayouts = 0;

      if (withRemote) {
        tempZones[zoneIndex].layouts.map((layout) => {
          totalLayouts = totalLayouts + layout.quantity;
          return totalLayouts;
        });

        if (totalLayouts > 10) {
          setLayoutQuantity(1);
        }
      }

      setCheckEnter(false);
    }

    return;
  }

  // Handle Enter key press
  const enterPress = useKeyPress('Enter');

  if (checkEnter && enterPress) {
    handleEnterClick();
    setCheckEnter(false);
  }

  function handleLayoutQuantityChange(e) {
    let targetValue = e.target.value;

    if (e.target.value > 0) {
      let tempZones = JSON.parse(JSON.stringify(zones));
      let totalLayouts = 0;

      if (withRemote) {
        tempZones[zoneIndex].layouts.map((layout) => {
          totalLayouts = totalLayouts + layout.quantity;
          return totalLayouts;
        });

        if (totalLayouts >= 10) {
          targetValue = 1;
        }
      }

      zones[zoneIndex].layouts[layoutIndex].quantity = parseInt(targetValue);
      tempZones[zoneIndex].layouts[layoutIndex].quantity = parseInt(
        targetValue
      );

      setLayoutQuantity(targetValue);
      setZones(tempZones);
      sessionStorage.setItem('jcc-global-state', JSON.stringify(global));
    }
  }

  return (
    <table className='component-table'>
      <thead>
        <tr className='component-table__header'>
          <td className='component-table__cell' data-table-header='Product'>
            Product
          </td>
          <td className='component-table__cell' data-table-header='Wattage'>
            Wattage
          </td>
          <td className='component-table__cell' data-table-header='Colour'>
            Colour
          </td>
          <td className='component-table__cell' data-table-header='IP Rating'>
            IP Rating
          </td>
          <td className='component-table__cell' data-table-header='Length'>
            Length
          </td>
          <td className='component-table__cell' data-table-header='Price'>
            Trade Price
          </td>
        </tr>
      </thead>
      <tbody>
        {layout.fields.map((field, index) => (
          <Fragment key={field.id + '-' + index}>
            <tr className='component-table__row'>
              <td className='component-table__cell' data-table-header='Product'>
                {field.value
                  ? field.value
                  : field.shorttitle
                  ? field.shorttitle
                  : field.title}
              </td>
              <td
                className='component-table__cell'
                data-table-header='Wattage'
                data-value={field.wattage ? 'true' : 'false'}
              >
                {field.wattage ? field.wattage : <span>–</span>}
              </td>
              <td
                className='component-table__cell'
                data-table-header='Colour'
                data-value={field.colourtemp ? 'true' : 'false'}
              >
                {field.colourtemp ? field.colourtemp : <span>–</span>}
              </td>
              <td
                className='component-table__cell'
                data-table-header='IP Rating'
                data-value={field.iprating ? 'true' : 'false'}
              >
                {field.iprating ? field.iprating : <span>–</span>}
              </td>
              <td
                className='component-table__cell'
                data-table-header='Length'
                data-value={field.length ? 'true' : 'false'}
              >
                {field.length ? field.length + 'mm' : <span>–</span>}
              </td>
              <td
                className='component-table__cell'
                data-table-header='Price'
                data-value={field.price ? 'true' : 'false'}
              >
                {field.price ? '£' + field.price.toFixed(2) : <span>–</span>}
              </td>
            </tr>
          </Fragment>
        ))}
        <tr className='component-table__row component-table__row--two'>
          <td className='component-table__cell' data-table-header='Product'>
            Trade Price{' '}
            <span>
              Price per kit using this layout, please check your discount at
              your electrical wholesaler
            </span>
          </td>
          <td className='component-table__cell' data-table-header='Price'>
            <strong>{'£' + layout.price.toFixed(2)}</strong>
          </td>
        </tr>
      </tbody>
      <tfoot className='component-table__footer component-table__row--two'>
        <tr className='component-table__row'>
          <td className='component-table__cell' data-table-header='Product'>
            Quantity{' '}
            <span>Number of identical kits required using this layout</span>
          </td>
          <td
            className='component-table__cell component-table__cell--input'
            data-table-header='Quantity'
          >
            <div className='input-submit'>
              <input
                type='number'
                id='qty'
                pattern='\d*' // this shows numeric keyboard on mobile https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input
                noValidate // see https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input#answer-15480933
                className='input-submit__input'
                min='1'
                value={layoutQuantity}
                onChange={(input) => handleLayoutQuantityChange(input)}
              />
              <button
                className='input-submit__button'
                onClick={() => handleEnterClick()}
              >
                Ok
              </button>
            </div>
          </td>
        </tr>
        <tr className='component-table__row component-table__row--two'>
          <td className='component-table__cell' data-table-header='Product'>
            Total{' '}
            <span>
              Combined price of all kits using this layout.
              <span style={{ marginLeft: 3, color: '#76b900' }}>
                Prices are valid for 30 days.
              </span>
            </span>
          </td>
          <td className='component-table__cell' data-table-header='Price'>
            <strong>
              £
              {(
                (layoutQuantity > 0 ? layoutQuantity : 1) * layout.price
              ).toFixed(2)}
            </strong>
          </td>
        </tr>
        <tr className='component-table__row component-table__row--discountmsg'>
          <td className='component-table__cell'>
            Remember to enter your wholesaler discount below to see accurate
            pricing
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default ReviewTableZones;
