import React, { useEffect, useState, useGlobal } from 'reactn';
import { Waypoint } from 'react-waypoint';
import ScrollToElement from '../../common/scrollToElement';
import FieldActionButtonsZones from '../../common/fieldActionButtonsZones';

const DesignQuantityFieldZones = (props) => {
  const { zoneIndex, layoutIndex, mobile, desktop } = props;

  const [isCurrent, setIsCurrent] = useState('false');
  const [zones, setZones] = useGlobal('zones');
  const [layoutQuantity, setLayoutQuantity] = useState(
    zones[zoneIndex].layouts[layoutIndex].quantity
  );
  const [canAddLayout, setCanAddLayout] = useState(true);
  const [global] = useGlobal();
  const [setupFieldChoices] = useGlobal('setupFieldChoices');
  const withRemote = setupFieldChoices.remoteQuantity > 0;

  let canAddZone = false;

  useEffect(() => {
    let tempZones = JSON.parse(JSON.stringify(zones));
    let totalLayouts = 0;
    let tempCanAddLayout = canAddLayout;

    if (withRemote) {
      tempZones[zoneIndex].layouts.map((layout) => {
        totalLayouts = totalLayouts + layout.quantity;
        return totalLayouts;
      });

      if (totalLayouts <= 9) {
        tempCanAddLayout = true;
      } else {
        tempCanAddLayout = false;
      }
    } else {
      tempCanAddLayout = true;
    }
    setCanAddLayout(tempCanAddLayout);
    // eslint-disable-next-line
  }, []);

  if (withRemote) {
    if (
      setupFieldChoices.remoteType === 'Handheld Remote' &&
      zones.length < 3
    ) {
      canAddZone = true;
    }
    if (
      setupFieldChoices.remoteType === 'Wall Mounted Remote' &&
      zones.length < 4
    ) {
      canAddZone = true;
    }
  }

  function handleLayoutQuantityChange(e) {
    let targetValue = e.target.value;

    if (e.target.value > 0) {
      let tempZones = JSON.parse(JSON.stringify(zones));
      let totalLayouts = 0;
      let tempCanAddLayout = canAddLayout;

      if (withRemote) {
        tempZones[zoneIndex].layouts.map((layout) => {
          totalLayouts = totalLayouts + layout.quantity;
          return totalLayouts;
        });

        if (totalLayouts < 9) {
          tempCanAddLayout = true;
        } else {
          tempCanAddLayout = false;
        }

        if (totalLayouts > 9) {
          targetValue = 1;
        }
      }

      zones[zoneIndex].layouts[layoutIndex].quantity = parseInt(targetValue);
      tempZones[zoneIndex].layouts[layoutIndex].quantity = parseInt(
        targetValue
      );

      setLayoutQuantity(targetValue);
      setCanAddLayout(tempCanAddLayout);
      setZones(tempZones);
      sessionStorage.setItem('jcc-global-state', JSON.stringify(global));
    }
  }

  return (
    <>
      <div
        className={
          isCurrent
            ? 'field field--slim has-selection current'
            : 'field has-selection'
        }
        id={
          `zone-` +
          (zoneIndex + 1) +
          `-layout-${zones[zoneIndex].layouts[layoutIndex].layoutNumber}-quantity`
        }
        key={
          `zone-` +
          (zoneIndex + 1) +
          `-layout-${zones[zoneIndex].layouts[layoutIndex].layoutNumber}-quantity`
        }
      >
        <div className='field__content-wrapper'>
          {desktop && (
            <div className='field__nav'>
              <button
                className='field__nav__prev'
                onClick={() =>
                  ScrollToElement(
                    zones[zoneIndex].layouts[layoutIndex].fields[
                      zones[zoneIndex].layouts[layoutIndex].fields.length - 1
                    ].id,
                    mobile,
                    200
                  )
                }
              >
                &lt;
              </button>
              <div className='field__number'>+</div>
              <div className='field__nav__next' disabled>
                &gt;
              </div>
            </div>
          )}
          <Waypoint
            onEnter={({ previousPosition, currentPosition, event }) => {
              let newZones = JSON.parse(JSON.stringify(zones));
              if (Waypoint.inside) {
                setIsCurrent(true);
                window.history.replaceState(
                  undefined,
                  undefined,
                  '#zone-' +
                    (zoneIndex + 1) +
                    '-layout-' +
                    zones[zoneIndex].layouts[layoutIndex].layoutNumber +
                    '-quantity'
                );
              }
              setZones(newZones);
            }}
            onLeave={({ previousPosition, currentPosition, event }) => {
              let newZones = JSON.parse(JSON.stringify(zones));
              if (Waypoint.above || Waypoint.below) {
                setIsCurrent(false);
              }
              setZones(newZones);
            }}
            scrollableAncestor={window}
            fireOnRapidScroll={false}
            topOffset='55%'
            bottomOffset='30%'
            debug={false}
            // key={cursor}
          >
            <div className='field__block'>
              <div className='field__header'>
                {mobile && <div className='field__number'>&#43;</div>}
                <h2 className='field__title'>
                  <span className='field__run-number'>
                    {withRemote
                      ? `Zone ` +
                        (zoneIndex + 1) +
                        `: Layout ` +
                        zones[zoneIndex].layouts[layoutIndex].layoutNumber
                      : `Layout ` +
                        zones[zoneIndex].layouts[layoutIndex].layoutNumber}
                  </span>
                  Quantity
                </h2>
                <p className='field__instruction'>
                  To order multiple kits using this layout, adjust the quantity
                  below.
                </p>
              </div>
              <div className='field__content'>
                <div className='input input--label-inline'>
                  <label htmlFor='qty'>Quantity</label>
                  <div className='input-submit'>
                    <input
                      type='number'
                      id='qty'
                      className='input-submit__input'
                      pattern='\d*' // this shows numeric keyboard on mobile https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input
                      noValidate // see https://stackoverflow.com/questions/6178556/phone-numeric-keyboard-for-text-input#answer-15480933
                      min='1'
                      value={layoutQuantity}
                      onChange={(input) => handleLayoutQuantityChange(input)}
                    />
                    <button className='input-submit__button'>Ok</button>
                  </div>
                </div>
                <FieldActionButtonsZones
                  zoneIndex={zoneIndex}
                  layoutIndex={layoutIndex}
                  showAddNextPieceButton={false}
                  showRemoveThisLayoutButton={layoutIndex > 0 ? true : false}
                  showEndLayoutButton={false}
                  showFinishDesignButton={true}
                  showRemoveThisPieceButton={false}
                  showAddAnotherLayoutButton={canAddLayout}
                  showAddAnotherZoneButton={canAddZone}
                  showRemoveThisZoneButton={zoneIndex > 0}
                  showEditLayoutButton={true}
                />
              </div>
            </div>
          </Waypoint>
        </div>
      </div>
    </>
  );
};

export default DesignQuantityFieldZones;
