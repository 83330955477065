import React from 'react';

const PageContentWrapper = props => {
  const { title, content } = props;

  return (
    <div className='page-content mobile-menu-push'>
      {title && (
        <div className='page-content__header'>
          <h3>{title}</h3>
        </div>  
      )}
      {content}
    </div>
  );
};

export default PageContentWrapper;
