import React, { useGlobal } from 'reactn';
import { Waypoint } from 'react-waypoint';
import classNames from 'classnames';
import ScrollToElement from '../../common/scrollToElement';
import FieldActionButtonsZones from '../../common/fieldActionButtonsZones';

const DesignFieldZones = (props) => {
  const {
    zoneIndex,
    layoutIndex,
    layoutNumber,
    lengthRemaining,
    fieldIndex,
    mobile,
    tablet,
    desktop,
    showFieldStatus,
    content,
  } = props;

  const [zones, setZones] = useGlobal('zones');
  const [setupFieldChoices] = useGlobal('setupFieldChoices');
  const withRemote = setupFieldChoices.remoteQuantity > 0;

  function calcPreviousFieldID() {
    if (fieldIndex > 0) {
      return zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex - 1].id;
    }
    return null;
  }
  function calcNextFieldID() {
    if (
      zones[zoneIndex].layouts[layoutIndex].complete &&
      fieldIndex === zones[zoneIndex].layouts[layoutIndex].fields.length - 1
    ) {
      return (
        'zone-' + (zoneIndex + 1) + '-layout-' + layoutNumber + '-quantity'
      );
    } else if (
      fieldIndex <
      zones[zoneIndex].layouts[layoutIndex].fields.length - 1
    ) {
      return zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex + 1].id;
    } else {
      return null;
    }
  }

  const previousFieldID = calcPreviousFieldID();
  const nextFieldID = calcNextFieldID();

  function displayFieldActionButtons() {
    if (
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
        .showaddnextpiecebutton ||
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
        .showdeletelayoutbutton ||
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
        .showendlayoutbutton ||
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
        .showfinishbutton ||
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
        .showremovepiecebutton ||
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
        .showaddlayoutbutton ||
      zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
        .showeditlayoutbutton
    ) {
      return (
        <FieldActionButtonsZones
          zoneIndex={zoneIndex}
          layoutIndex={layoutIndex}
          fieldIndex={fieldIndex}
          showAddNextPieceButton={
            zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
              .showaddnextpiecebutton
          }
          showRemoveThisLayoutButton={
            zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
              .showdeletelayoutbutton
          }
          showEndLayoutButton={
            zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
              .showendlayoutbutton
          }
          showFinishDesignButton={
            zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
              .showfinishbutton
          }
          showRemoveThisPieceButton={
            zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
              .showremovepiecebutton
          }
          showAddAnotherLayoutButton={
            zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
              .showaddlayoutbutton
          }
          showEditLayoutButton={
            zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
              .showeditlayoutbutton
          }
        />
      );
    } else {
      return;
    }
  }

  const fieldClass = classNames({
    field: true,
    current: zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].current,
    'has-selection': true,
  });

  return (
    <>
      <div
        className={fieldClass}
        id={zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex].id}
        key={fieldIndex + 1}
      >
        <div className='field__content-wrapper'>
          {desktop && (
            <div className='field__nav'>
              {previousFieldID && (
                <button
                  className='field__nav__prev'
                  onClick={() => ScrollToElement(previousFieldID, mobile, 200)}
                >
                  &lt;
                </button>
              )}
              {!previousFieldID && (
                <div className='field__nav__prev' disabled>
                  &lt;
                </div>
              )}

              <div className='field__number'>{fieldIndex + 1}</div>
              {nextFieldID && (
                <button
                  className='field__nav__next'
                  onClick={() => ScrollToElement(nextFieldID, mobile, 200)}
                >
                  &gt;
                </button>
              )}
              {!nextFieldID && (
                <div className='field__nav__next' disabled>
                  &gt;
                </div>
              )}
            </div>
          )}
          <Waypoint
            onEnter={({ previousPosition, currentPosition, event }) => {
              let newZones = JSON.parse(JSON.stringify(zones));
              if (Waypoint.inside) {
                newZones[zoneIndex].layouts[layoutIndex].fields[
                  fieldIndex
                ].current = true;
                window.history.replaceState(
                  undefined,
                  undefined,
                  '#' +
                    newZones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                      .id
                );
              }
              setZones(newZones);
            }}
            onLeave={({ previousPosition, currentPosition, event }) => {
              let newZones = JSON.parse(JSON.stringify(zones));

              if (Waypoint.above || Waypoint.below) {
                newZones[zoneIndex].layouts[layoutIndex].fields[
                  fieldIndex
                ].current = false;
                // const numOptions =
                //   newLayouts[layoutIndex].fields[fieldIndex].options.length;
                // for (let i = 0; i < numOptions; i++) {
                //   if (
                //     newLayouts[layoutIndex].fields[fieldIndex].options[i].type ===
                //       'input' &&
                //     newLayouts[layoutIndex].fields[fieldIndex].options[i].showinput
                //   ) {
                //     newLayouts[layoutIndex].fields[fieldIndex].value = '';
                //     newLayouts[layoutIndex].fields[fieldIndex].options[
                //       i
                //     ].showinput = false;
                //     newLayouts[layoutIndex].fields[fieldIndex].options[
                //       i
                //     ].selected = false;
                //     newLayouts[layoutIndex].fields[fieldIndex].options[
                //       i
                //     ].deselected = true;
                //   }
                // }
              }
              setZones(newZones);
            }}
            scrollableAncestor={window}
            fireOnRapidScroll={false}
            topOffset='55%'
            bottomOffset='30%'
            debug={false}
            // key={cursor}
          >
            <div className='field__block'>
              <div className='field__header'>
                {mobile && (
                  <div className='field__number'>{fieldIndex + 1}</div>
                )}
                <h2 className='field__title'>
                  {layoutNumber && (
                    <span className='field__run-number'>
                      {withRemote
                        ? `Zone ` +
                          (zoneIndex + 1) +
                          `: Layout ` +
                          zones[zoneIndex].layouts[layoutIndex].layoutNumber
                        : `Layout ` +
                          zones[zoneIndex].layouts[layoutIndex].layoutNumber}
                    </span>
                  )}
                  {withRemote && fieldIndex === 0
                    ? zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                        .title + ' and LED Controller'
                    : zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                        .title}
                  {showFieldStatus && (tablet || desktop) && (
                    <p className='field__status'>
                      <span>{lengthRemaining / 1000 + 'm'}</span> strip remaining
                      in this layout
                    </p>
                  )}
                </h2>
                <p className='field__instruction'>
                  {
                    zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                      .instruction
                  }
                  {showFieldStatus &&
                    mobile &&
                    !tablet &&
                    zones[zoneIndex].layouts[layoutIndex].fields[fieldIndex]
                      .title === 'Straight LED Strip' && (
                      <span className='field__status'>
                        {' '}
                        You have <span>{lengthRemaining / 1000 + 'm'}</span> LED
                        Strip remaining in this layout.
                      </span>
                    )}
                </p>
              </div>
              <div className='field__content'>
                {content}
                {displayFieldActionButtons()}
              </div>
            </div>
          </Waypoint>
        </div>
      </div>
    </>
  );
};

export default DesignFieldZones;
