import React, { setGlobal, useGlobal, useEffect } from 'reactn';
// import { Link } from 'react-router-dom';
import SideBar from '../../common/sideBar';
import UpdateLayoutsForReviewZones from '../../common/updateLayoutsForReviewZones';
import UpdateZonesForSend from '../../common/updateZonesForSend';
import PageContentWrapper from '../../common/pageContentWrapper';
import ReviewPageContentZones from './reviewPageContentZones';
import AppFooter from '../../common/appFooter';
import MobileHeader from '../../common/mobileHeader';
import MobileMenu from '../../common/mobileMenu';
import ScrollToElement from '../../common/scrollToElement';

const ReviewPage = (props) => {
  const { mobile, desktop, reactGA } = props;
  const [pageAttributes] = useGlobal('pageAttributes');
  const [zones] = useGlobal('zones');
  const [, setZonesToSend] = useGlobal('zonesToSend');
  const [global] = useGlobal();

  reactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    if (zones.length === 0) {
      window.location.href = '/';
      return;
    }
    sessionStorage.setItem('jcc-global-state', JSON.stringify(global));
    ScrollToElement('top', 0);
    // eslint-disable-next-line
  }, []);

  pageAttributes.reviewPage.status = 'incomplete';
  pageAttributes.sendPage.status = 'review required';

  let updatedZoneLayouts = JSON.parse(JSON.stringify(zones));
  UpdateLayoutsForReviewZones(updatedZoneLayouts);

  let tempZones = JSON.parse(JSON.stringify(zones));
  UpdateZonesForSend(tempZones);

  function handleConfirmDesignButtonClick() {
    setZonesToSend(tempZones);
    pageAttributes.setupPage.status = 'complete';
    pageAttributes.designPage.status = 'complete';
    pageAttributes.reviewPage.status = 'complete';
    pageAttributes.sendPage.status = 'incomplete';
    sessionStorage.setItem('jcc-global-state', JSON.stringify(global));
  }

  const sidebarContent = (
    <>
      <div className='sidebar__instruction'>
        <p className='lead'>Check and confirm your layout designs</p>
        <p>
          Please review the layouts you have designed and confirm your design
          to&nbsp;continue.
        </p>
        {/* <div className='button-group'>
          <Link
            className='button button--tertiary button--next-page'
            to={{
              pathname: 'send',
              hash: ''
            }}
            onClick={() => {
              handleConfirmDesignButtonClick();
            }}
          >
            Confirm design
          </Link>
        </div> */}
      </div>
    </>
  );

  function handleMobileMenuOpen() {
    document.body.classList.add('mobile-menu-open');
    setGlobal({ mobileMenuOpen: true });
  }

  return (
    <>
      <div className='page'>
        {mobile && (
          <MobileHeader
            title='Review'
            onClickHandler={() => handleMobileMenuOpen()}
          />
        )}
        {desktop && <SideBar title='Review' content={sidebarContent} />}
        <PageContentWrapper
          content={
            <ReviewPageContentZones
              mobile={mobile}
              desktop={desktop}
              pageAttributes={pageAttributes}
              updatedZoneLayouts={updatedZoneLayouts}
              confirmDesign={() => handleConfirmDesignButtonClick()}
            />
          }
        />
        {desktop && <AppFooter desktop={true} />}
      </div>
      {mobile && <MobileMenu />}
    </>
  );
};

export default ReviewPage;
