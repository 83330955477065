import React, { useGlobal } from 'reactn';
import { Waypoint } from 'react-waypoint';
import classNames from 'classnames';
import ScrollToElement from '../../common/scrollToElement';
import FieldActionButtonsZones from '../../common/fieldActionButtonsZones';

const SetupField = (props) => {
  const {
    fields,
    fieldsUpdateFunction,
    fieldIndex,
    mobile,
    desktop,
    previousFieldID,
    nextFieldID,
    content,
  } = props;

  const [zones] = useGlobal('zones');

  function displayFieldActionButtons() {
    if (
      fields[fieldIndex].value === 'Custom Design' ||
      (fields[fieldIndex].value === 'Single Length' &&
        fields[fieldIndex].length > 0)
    ) {
      return (
        <FieldActionButtonsZones
          showSetupCompleteStartCustomButton={
            fields[fieldIndex].value === 'Custom Design' &&
            zones[0].layouts[0].fields.length === 1
              ? true
              : false
          }
          showSetupCompleteContinueCustomButton={
            fields[fieldIndex].value === 'Custom Design' &&
            zones[0].layouts[0].fields.length > 1
              ? true
              : false
          }
          showSetupCompleteSingleButton={
            fields[fieldIndex].value === 'Single Length' &&
            fields[fieldIndex].length > 0
              ? true
              : false
          }
        />
      );
    } else {
      return;
    }
  }

  const fieldClass = classNames({
    field: true,
    current: fields[fieldIndex].current,
    'has-selection': fields[fieldIndex].value.length > 2,
  });

  return (
    <>
      <div
        className={fieldClass}
        id={fields[fieldIndex].id}
        key={fieldIndex + 1}
      >
        <div className='field__content-wrapper'>
          {desktop && (
            <div className='field__nav'>
              {previousFieldID && (
                <button
                  className='field__nav__prev'
                  onClick={() => ScrollToElement(previousFieldID, mobile, 200)}
                >
                  &lt;
                </button>
              )}
              {!previousFieldID && (
                <div className='field__nav__prev' disabled>
                  &lt;
                </div>
              )}

              <div className='field__number'>{fieldIndex + 1}</div>
              {nextFieldID && (
                <button
                  className='field__nav__next'
                  onClick={() => ScrollToElement(nextFieldID, mobile, 200)}
                >
                  &gt;
                </button>
              )}
              {!nextFieldID && (
                <div className='field__nav__next' disabled>
                  &gt;
                </div>
              )}
            </div>
          )}
          <Waypoint
            onEnter={({ previousPosition, currentPosition, event }) => {
              if (Waypoint.inside) {
                fields[fieldIndex].current = true;
                fields[fieldIndex].disabled = false;
                window.history.replaceState(
                  undefined,
                  undefined,
                  '#' + fields[fieldIndex].id
                );
              }
              fieldsUpdateFunction(fields);
            }}
            onLeave={({ previousPosition, currentPosition, event }) => {
              if (Waypoint.above || Waypoint.below) {
                fields[fieldIndex].current = false;
                const numOptions = fields[fieldIndex].options.length;
                for (let i = 0; i < numOptions; i++) {
                  if (
                    fields[fieldIndex].options[i].type === 'input' &&
                    fields[fieldIndex].options[i].showinput
                  ) {
                    fields[fieldIndex].value = '';
                    fields[fieldIndex].options[i].showinput = false;
                    fields[fieldIndex].options[i].selected = false;
                    fields[fieldIndex].options[i].deselected = true;
                  }
                }
                fieldsUpdateFunction(fields);
              }
            }}
            scrollableAncestor={window}
            fireOnRapidScroll={false}
            topOffset='55%'
            bottomOffset='30%'
            debug={false}
            // key={cursor}
          >
            <div className='field__block'>
              <div className='field__header'>
                {mobile && (
                  <div className='field__number'>{fieldIndex + 1}</div>
                )}
                <h2 className='field__title'>{fields[fieldIndex].title}</h2>
                <p className='field__instruction'>
                  {fields[fieldIndex].instruction}
                  {fields[fieldIndex].id === 'profile' && <><br />
                  <span style={{color: '#414042'}}>*Slimline profile not suitable for IP65 LED Strip</span></>}
                </p>
              </div>
              <div className='field__content'>
                {content}
                {displayFieldActionButtons()}
              </div>
            </div>
          </Waypoint>
        </div>
      </div>
    </>
  );
};

export default SetupField;
