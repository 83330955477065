import React, { setGlobal } from 'reactn';
import AppTitle from './appTitle';
import PageNav from './pageNav';
import AppActions from './appActions';

function handleMobileMenuClose() {
  document.body.classList.remove('mobile-menu-open');
  setGlobal({ mobileMenuOpen: false });
}

const MobileMenu = () => {
  return (
    <div className='mobile-menu'>
      <button
        className='mobile-menu-close'
        onClick={() => handleMobileMenuClose()}
      />
      <AppTitle />
      <div className='mobile-menu__content'>
        <PageNav mobile={true} onMobileNav={() => handleMobileMenuClose()} />
        <AppActions />
      </div>
    </div>
  );
};

export default MobileMenu;
